import _ from 'lodash';

import type { DrawerProps } from 'antd';
import type { ProDescriptionsActionType } from "@ant-design/pro-descriptions";
import {ProFormDatePicker, ProFormProps} from '@ant-design/pro-form';

import React, { useRef } from 'react';
import {Button, Drawer, Space, Row, Col, Form, } from 'antd';
import {FooterToolbar} from '@ant-design/pro-layout';
import ProForm, {ModalForm, DrawerForm, ProFormText, } from '@ant-design/pro-form';

import FormLayout from './../../../../utils/ui/layout/FormLayout';

import { TowerCraneMachineMetaInfo } from './../../type/TowerCraneMachineMetaInfoType';
import TowerCraneMachineMetaInfoApi from '../../api/TowerCraneMachineMetaInfoApi';
import { message } from 'antd';
import DictItemSelector from '../../../system/pages/DictItem/DictItemSelector';
import DustMachinePlatformSelector from '../DustMachinePlatform/DustMachinePlatformSelector';
import CompanyModalSelector from '../../../jz/pages/Company/CompanyModalSelector';
import TowerCraneMachinePlatformSelector from '../TowerCraneMachinePlatform/TowerCraneMachinePlatformSelector';

export interface TowerCraneMachineMetaInfoAddFormProps extends DrawerProps {
    onFail:()=>void,
    onSuccess:()=>void,
};



const TowerCraneMachineMetaInfoAddForm:React.FC<TowerCraneMachineMetaInfoAddFormProps> = (props)=>{

    const [form] = Form.useForm<TowerCraneMachineMetaInfo>();
    const {onFail, onSuccess} = props;

    return (
        <Drawer
            title="新建塔吊元信息"
            destroyOnClose={false}
            closable={false}
            width={960}
            extra={[
                <Button
                    key='closeBtn'
                    type='primary'
                    style={{backgroundColor:'red', border:0}}
                    onClick={onFail}
                >
                    关闭
                </Button>
            ]}
            {..._.omit(props, 'onFail', 'onSuccess')}
        >
            <ProForm
                submitter={{
                    render: (_, dom) => <div style={{textAlign:'right', marginTop:16}}><Space>{dom}</Space></div>,
                }}
                onFinish={async (values:  TowerCraneMachineMetaInfo)=>{
                    await TowerCraneMachineMetaInfoApi.addTowerCraneMachineMetaInfo(values);
                    form.resetFields();
                    message.success("新建塔吊元信息成功！");
                    if(onSuccess){
                        onSuccess();
                    }else{
                        onFail();
                    }
                    return true;
                }}
                form={form}
                layout={'horizontal'}
            >
                <Row>
                    <Col span={12}>
                        <ProFormText
                            name={['sn']}
                            label="序列号"
                            tooltip=""
                            placeholder=""
                            rules={[{required:true, message: "序列号不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <DictItemSelector
                            entryCode='IOT_TOWER_CRANE_BRAND'
                            name={['brand']}
                            label="品牌"
                            tooltip=""
                            placeholder=""
                            rules={[{required:true, message: "品牌不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <DictItemSelector
                            entryCode='IOT_TOWER_CRANE_TYPE'
                            name={['type']}
                            label="型号"
                            tooltip=""
                            placeholder=""
                            rules={[{required:true, message: "型号不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <TowerCraneMachinePlatformSelector
                            name={['platformId']}
                            label="平台ID"
                            tooltip=""
                            placeholder=""
                            rules={[{required:true, message: "平台ID不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <CompanyModalSelector
                            name={['supplierId']}
                            label="供应商"
                            tooltip=""
                            placeholder=""
                            rules={[{required:false, message: "供应商不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <CompanyModalSelector
                            name={['agentId']}
                            label="代理商"
                            tooltip=""
                            placeholder=""
                            rules={[{required:false, message: "代理商不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <CompanyModalSelector
                            name={['buyerId']}
                            label="采购商"
                            tooltip=""
                            placeholder=""
                            rules={[{required:false, message: "采购商不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormDatePicker
                            name={['purchaseDate']}
                            label="采购日期"
                            tooltip=""
                            placeholder=""
                            rules={[{required:false, message: "采购日期不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                </Row>
            </ProForm>
        </Drawer>
    );
};

export default TowerCraneMachineMetaInfoAddForm;