import React, {useState, useEffect} from "react";

import type {SelectProps} from 'antd';
import { Select } from "antd";
import DustMachinePlatformApi from "../../api/DustMachinePlatformApi";
import type { DustMachinePlatform } from "../../type/DustMachinePlatformType";
import { ProFormSelectProps } from "@ant-design/pro-form/lib/components/Select";
import { ProFormSelect } from "@ant-design/pro-form";

const DustMachinePlatformSelector:React.FC<ProFormSelectProps> = (props)=>{

    const [options, setOptions] = useState<[]>([]);

    useEffect(()=>{
        DustMachinePlatformApi.findDustMachinePlatformList({
            limit: 100,
            deletedFlag: false,
        }).then((result:any)=>{
            const newOptions = result.map((dustMachinePlatform:DustMachinePlatform)=>{
                return {
                    key: dustMachinePlatform.id,
                    value: dustMachinePlatform.id,
                    label: dustMachinePlatform.name,
                };
            });

            setOptions(newOptions);
        });
    }, []);

    return (
        <ProFormSelect
            options={options}
            {...props}
        />
    );
};

export default DustMachinePlatformSelector;