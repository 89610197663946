import _ from 'lodash';

import type { DrawerProps } from 'antd';
import type { ProDescriptionsActionType } from "@ant-design/pro-descriptions";

import React, { useRef, useState } from 'react';
import { Button, Drawer, message,   } from 'antd';
import ProDescriptions from '@ant-design/pro-descriptions';
import ProForm, {ProFormText, } from '@ant-design/pro-form';
import { OutsideElevatorLog } from './../../type/OutsideElevatorLogType';
import OutsideElevatorLogApi from '../../api/OutsideElevatorLogApi';

export interface OutsideElevatorLogDetailProps extends DrawerProps {
    outsideElevatorLog?: OutsideElevatorLog,
    actionRef?: React.MutableRefObject<ProDescriptionsActionType | undefined>,
    onFail:()=>void,
    onSuccess:()=>void,
};

const OutsideElevatorLogDetail:React.FC<OutsideElevatorLogDetailProps> = (props)=>{

    const {outsideElevatorLog, onFail, onSuccess, actionRef} = props;
    const [title, setTitle] = useState<string|undefined>(outsideElevatorLog?.name);

    return (
        <Drawer
            title={title}
            width={960}
            closable={false}
            destroyOnClose={true}
            extra={[
                <Button
                        key='closeBtn'
                        type='primary'
                        style={{backgroundColor:'red', border:0}}
                        onClick={onFail}
                >
                    关闭
                </Button>
            ]}
            {..._.omit(props, 'onFail', 'onSuccess', 'actionRef')}
        >
            <ProDescriptions<OutsideElevatorLog>
                column={2}
                actionRef={actionRef}
                request={async ()=>{
                    const result:OutsideElevatorLog = await OutsideElevatorLogApi.findOutsideElevatorLogById(outsideElevatorLog?.id||"");
                    setTitle(result?.name);

                    return {
                        success: true,
                        data: result,
                    };
                }}
                editable={{
                    onSave:async (keyPath:any, newData, oldData)=>{
                        const requestData:any = {
                            id: oldData?.id
                        };
                        let rTemp = requestData;
                        let nTemp = newData;

                        for(let i=0; i<keyPath.length; i++){
                            let field = keyPath[i];

                            if(i===keyPath.length-1){
                                rTemp[field] = nTemp[field];
                            }else{
                                rTemp[field] = {};
                                rTemp = rTemp[field];
                                nTemp = nTemp[field];
                            }
                        }

                        await OutsideElevatorLogApi.patchEditOutsideElevatorLog(requestData);
                        actionRef?.current?.reload();
                        onSuccess();
                    }
                }}
            >
                    <ProDescriptions.Item
                        dataIndex={['outKey']}
                        label="外部记录ID"
                        valueType="text"
                        copyable={false}
                        render={(dom, entity, index, action) => {
                            return (
                                <div>
                                    {dom}
                                </div>
                            );
                        }}
                        renderFormItem={() => {
                            return <ProFormText />;
                        }}
                    />
                    <ProDescriptions.Item
                        dataIndex={['deviceSn']}
                        label="设备序列号"
                        valueType="text"
                        copyable={false}
                        render={(dom, entity, index, action) => {
                            return (
                                <div>
                                    {dom}
                                </div>
                            );
                        }}
                        renderFormItem={() => {
                            return <ProFormText />;
                        }}
                    />
                    <ProDescriptions.Item
                        dataIndex={['deviceTime']}
                        label="设备时间"
                        valueType="text"
                        copyable={false}
                        render={(dom, entity, index, action) => {
                            return (
                                <div>
                                    {dom}
                                </div>
                            );
                        }}
                        renderFormItem={() => {
                            return <ProFormText />;
                        }}
                    />
                    <ProDescriptions.Item
                        dataIndex={['load']}
                        label="载重"
                        valueType="text"
                        copyable={false}
                        render={(dom, entity, index, action) => {
                            return (
                                <div>
                                    {dom}
                                </div>
                            );
                        }}
                        renderFormItem={() => {
                            return <ProFormText />;
                        }}
                    />
                    <ProDescriptions.Item
                        dataIndex={['loadPercent']}
                        label="载重百分比"
                        valueType="text"
                        copyable={false}
                        render={(dom, entity, index, action) => {
                            return (
                                <div>
                                    {dom}
                                </div>
                            );
                        }}
                        renderFormItem={() => {
                            return <ProFormText />;
                        }}
                    />
                    <ProDescriptions.Item
                        dataIndex={['peopleCount']}
                        label="人数"
                        valueType="text"
                        copyable={false}
                        render={(dom, entity, index, action) => {
                            return (
                                <div>
                                    {dom}
                                </div>
                            );
                        }}
                        renderFormItem={() => {
                            return <ProFormText />;
                        }}
                    />
                    <ProDescriptions.Item
                        dataIndex={['windSpeed']}
                        label="风速"
                        valueType="text"
                        copyable={false}
                        render={(dom, entity, index, action) => {
                            return (
                                <div>
                                    {dom}
                                </div>
                            );
                        }}
                        renderFormItem={() => {
                            return <ProFormText />;
                        }}
                    />
                    <ProDescriptions.Item
                        dataIndex={['height']}
                        label="高度"
                        valueType="text"
                        copyable={false}
                        render={(dom, entity, index, action) => {
                            return (
                                <div>
                                    {dom}
                                </div>
                            );
                        }}
                        renderFormItem={() => {
                            return <ProFormText />;
                        }}
                    />
                    <ProDescriptions.Item
                        dataIndex={['speed']}
                        label="升降机运行速度"
                        valueType="text"
                        copyable={false}
                        render={(dom, entity, index, action) => {
                            return (
                                <div>
                                    {dom}
                                </div>
                            );
                        }}
                        renderFormItem={() => {
                            return <ProFormText />;
                        }}
                    />
                    <ProDescriptions.Item
                        dataIndex={['totalMileage']}
                        label="升降机运行里程"
                        valueType="text"
                        copyable={false}
                        render={(dom, entity, index, action) => {
                            return (
                                <div>
                                    {dom}
                                </div>
                            );
                        }}
                        renderFormItem={() => {
                            return <ProFormText />;
                        }}
                    />
                    <ProDescriptions.Item
                        dataIndex={['slantAngel']}
                        label="倾斜角度"
                        valueType="text"
                        copyable={false}
                        render={(dom, entity, index, action) => {
                            return (
                                <div>
                                    {dom}
                                </div>
                            );
                        }}
                        renderFormItem={() => {
                            return <ProFormText />;
                        }}
                    />
                    <ProDescriptions.Item
                        dataIndex={['callFloor']}
                        label="呼叫楼层"
                        valueType="text"
                        copyable={false}
                        render={(dom, entity, index, action) => {
                            return (
                                <div>
                                    {dom}
                                </div>
                            );
                        }}
                        renderFormItem={() => {
                            return <ProFormText />;
                        }}
                    />
                    <ProDescriptions.Item
                        dataIndex={['currentFloor']}
                        label="当前楼层"
                        valueType="text"
                        copyable={false}
                        render={(dom, entity, index, action) => {
                            return (
                                <div>
                                    {dom}
                                </div>
                            );
                        }}
                        renderFormItem={() => {
                            return <ProFormText />;
                        }}
                    />
                    <ProDescriptions.Item
                        dataIndex={['limitSwitch']}
                        label="限位开关"
                        valueType="text"
                        copyable={false}
                        render={(dom, entity, index, action) => {
                            return (
                                <div>
                                    {dom}
                                </div>
                            );
                        }}
                        renderFormItem={() => {
                            return <ProFormText />;
                        }}
                    />
                    <ProDescriptions.Item
                        dataIndex={['runningState']}
                        label="限位开关"
                        valueType="text"
                        copyable={false}
                        render={(dom, entity, index, action) => {
                            return (
                                <div>
                                    {dom}
                                </div>
                            );
                        }}
                        renderFormItem={() => {
                            return <ProFormText />;
                        }}
                    />
                    <ProDescriptions.Item
                        dataIndex={['bypass']}
                        label="Bypass开关"
                        valueType="text"
                        copyable={false}
                        render={(dom, entity, index, action) => {
                            return (
                                <div>
                                    {dom}
                                </div>
                            );
                        }}
                        renderFormItem={() => {
                            return <ProFormText />;
                        }}
                    />
                    <ProDescriptions.Item
                        dataIndex={['totalRunDays']}
                        label="总运行天数"
                        valueType="text"
                        copyable={false}
                        render={(dom, entity, index, action) => {
                            return (
                                <div>
                                    {dom}
                                </div>
                            );
                        }}
                        renderFormItem={() => {
                            return <ProFormText />;
                        }}
                    />
                    <ProDescriptions.Item
                        dataIndex={['firstRunDate']}
                        label="首次运行日期"
                        valueType="text"
                        copyable={false}
                        render={(dom, entity, index, action) => {
                            return (
                                <div>
                                    {dom}
                                </div>
                            );
                        }}
                        renderFormItem={() => {
                            return <ProFormText />;
                        }}
                    />
                    <ProDescriptions.Item
                        dataIndex={['openTime']}
                        label="本次开机时间"
                        valueType="text"
                        copyable={false}
                        render={(dom, entity, index, action) => {
                            return (
                                <div>
                                    {dom}
                                </div>
                            );
                        }}
                        renderFormItem={() => {
                            return <ProFormText />;
                        }}
                    />
                    <ProDescriptions.Item
                        dataIndex={['driverId']}
                        label="司机ID"
                        valueType="text"
                        copyable={false}
                        render={(dom, entity, index, action) => {
                            return (
                                <div>
                                    {dom}
                                </div>
                            );
                        }}
                        renderFormItem={() => {
                            return <ProFormText />;
                        }}
                    />
                    <ProDescriptions.Item
                        dataIndex={['certNo']}
                        label="作业证号"
                        valueType="text"
                        copyable={false}
                        render={(dom, entity, index, action) => {
                            return (
                                <div>
                                    {dom}
                                </div>
                            );
                        }}
                        renderFormItem={() => {
                            return <ProFormText />;
                        }}
                    />
                    <ProDescriptions.Item
                        dataIndex={['elevatorLogId']}
                        label="养成记录ID"
                        valueType="text"
                        copyable={false}
                        render={(dom, entity, index, action) => {
                            return (
                                <div>
                                    {dom}
                                </div>
                            );
                        }}
                        renderFormItem={() => {
                            return <ProFormText />;
                        }}
                    />
                    <ProDescriptions.Item
                        dataIndex={['createdTime']}
                        label="创建时间"
                        valueType="text"
                        copyable={false}
                        render={(dom, entity, index, action) => {
                            return (
                                <div>
                                    {dom}
                                </div>
                            );
                        }}
                        renderFormItem={() => {
                            return <ProFormText />;
                        }}
                    />
                    <ProDescriptions.Item
                        dataIndex={['updatedTime']}
                        label="更新时间"
                        valueType="text"
                        copyable={false}
                        render={(dom, entity, index, action) => {
                            return (
                                <div>
                                    {dom}
                                </div>
                            );
                        }}
                        renderFormItem={() => {
                            return <ProFormText />;
                        }}
                    />
            </ProDescriptions>
        </Drawer>
    );
};

export default OutsideElevatorLogDetail;