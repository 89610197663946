import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';

import dva from 'dva';
import * as history from 'history';
import 'moment/locale/zh-cn';

import './index.less';
import { notification } from 'antd';

// 1. Initialize
const app:any = dva({
  history: history.createHashHistory(),
  onError:(error:any)=>{
    
  }
});

// 2. Plugins

// 3. Register global model
app.model(require('./modules/main/models/global').default);

// 4. Router
app.router(require('./RouteConfig').default);

// 5. Start
app.start('#root');

export default app._store; // eslint-disable-line
