
import {OutsideTowerCraneLog, OutsideTowerCraneLogQto} from './../type/OutsideTowerCraneLogType';
import HttpInvoker from './../../../utils/http/HttpInvoker';


const addOutsideTowerCraneLog = async (params:OutsideTowerCraneLog)=>{

    return HttpInvoker.postObject(`/api/tenant/iot/outside-tower-crane-log`, params);
};


const editOutsideTowerCraneLog = async (params:OutsideTowerCraneLog)=>{

    return HttpInvoker.putObject(`/api/tenant/iot/outside-tower-crane-log/${params.id}`, params);
};


const patchEditOutsideTowerCraneLog = async (params:OutsideTowerCraneLog)=>{

    return HttpInvoker.patchObject(`/api/tenant/iot/outside-tower-crane-log/${params.id}`, params);
};


const deleteOutsideTowerCraneLog = async (params:OutsideTowerCraneLog)=>{

    return HttpInvoker.deleteObject(`/api/tenant/iot/outside-tower-crane-log/${params.id}`, params);
};


const findOutsideTowerCraneLogById = async (params:string)=>{

    return HttpInvoker.getObject(`/api/tenant/iot/outside-tower-crane-log/${params}`, params);
};

const findOutsideTowerCraneLogList = async (params:any)=>{

    return HttpInvoker.getObject(`/api/tenant/iot/outside-tower-crane-log/list`, params);
};

const findOutsideTowerCraneLogPage = async (params:any)=>{

    return HttpInvoker.getObject(`/api/tenant/iot/outside-tower-crane-log/page`, params);
};



export default {
    addOutsideTowerCraneLog,
    editOutsideTowerCraneLog,
    patchEditOutsideTowerCraneLog,
    deleteOutsideTowerCraneLog,
    findOutsideTowerCraneLogById,
    findOutsideTowerCraneLogList,
    findOutsideTowerCraneLogPage,
};


