import _ from 'lodash';

import type { DrawerProps,  } from 'antd';
import type { ProDescriptionsActionType } from "@ant-design/pro-descriptions";

import React, { useRef, useState } from 'react';
import { Button, Drawer, message,  Tabs } from 'antd';
import ProDescriptions from '@ant-design/pro-descriptions';
import ProForm, {ProFormText, } from '@ant-design/pro-form';
import { ProjectInfo } from './../../type/ProjectInfoType';
import ProjectInfoApi from '../../api/ProjectInfoApi';

import IotGovPlatformTenantSetting from '../../../iot-sync/pages/IotGovPlatformTenantSetting';
import GovPlatformTenantSettingPage from '../../../hr-sync/pages/GovPlatformTenantSetting';

export interface ProjectInfoDetailProps extends DrawerProps {
    projectInfo?: ProjectInfo,
    actionRef?: React.MutableRefObject<ProDescriptionsActionType | undefined>,
    onFail:()=>void,
    onSuccess:()=>void,
};

const ProjectInfoDetail:React.FC<ProjectInfoDetailProps> = (props)=>{

    const {projectInfo, onFail, onSuccess, actionRef} = props;
    const [title, setTitle] = useState<string|undefined>(projectInfo?.name);

    return (
        <Drawer
            title={title}
            width={960}
            closable={false}
            destroyOnClose={true}
            bodyStyle={{paddingTop:0}}
            extra={[
                <Button
                        key='closeBtn'
                        type='primary'
                        style={{backgroundColor:'red', border:0}}
                        onClick={onFail}
                >
                    关闭
                </Button>
            ]}
            {..._.omit(props, 'onFail', 'onSuccess', 'actionRef')}
        >
            <Tabs>
                <Tabs.TabPane tab="项目档案" key="basicInfo">
                    <ProDescriptions<ProjectInfo>
                        column={2}
                        actionRef={actionRef}
                        request={async ()=>{
                            const result:ProjectInfo = await ProjectInfoApi.findProjectInfoById(projectInfo?.id||"");
                            setTitle(result?.name);

                            return {
                                success: true,
                                data: result,
                            };
                        }}
                        editable={{
                            onSave:async (keyPath:any, newData, oldData)=>{
                                const requestData:any = {
                                    id: oldData?.id
                                };
                                let rTemp = requestData;
                                let nTemp = newData;

                                for(let i=0; i<keyPath.length; i++){
                                    let field = keyPath[i];

                                    if(i===keyPath.length-1){
                                        rTemp[field] = nTemp[field];
                                    }else{
                                        rTemp[field] = {};
                                        rTemp = rTemp[field];
                                        nTemp = nTemp[field];
                                    }
                                }

                                await ProjectInfoApi.patchEditProjectInfo(requestData);
                                actionRef?.current?.reload();
                                onSuccess();
                            }
                        }}
                    >
                            <ProDescriptions.Item
                                dataIndex={['name']}
                                label="工程名称"
                                valueType="text"
                                copyable={false}
                                render={(dom, entity, index, action) => {
                                    return (
                                        <div>
                                            {dom}
                                        </div>
                                    );
                                }}
                                renderFormItem={() => {
                                    return <ProFormText />;
                                }}
                            />
                            <ProDescriptions.Item
                                dataIndex={['code']}
                                label="项目代码"
                                valueType="text"
                                copyable={false}
                                render={(dom, entity, index, action) => {
                                    return (
                                        <div>
                                            {dom}
                                        </div>
                                    );
                                }}
                                renderFormItem={() => {
                                    return <ProFormText />;
                                }}
                            />
                            <ProDescriptions.Item
                                dataIndex={['status']}
                                label="工程状态"
                                valueType="text"
                                copyable={false}
                                render={(dom, entity, index, action) => {
                                    return (
                                        <div>
                                            {dom}
                                        </div>
                                    );
                                }}
                                renderFormItem={() => {
                                    return <ProFormText />;
                                }}
                            />
                            <ProDescriptions.Item
                                dataIndex={['category']}
                                label="项目分类"
                                valueType="text"
                                copyable={false}
                                render={(dom, entity, index, action) => {
                                    return (
                                        <div>
                                            {dom}
                                        </div>
                                    );
                                }}
                                renderFormItem={() => {
                                    return <ProFormText />;
                                }}
                            />
                            <ProDescriptions.Item
                                dataIndex={['approvalLevel']}
                                label="立项级别"
                                valueType="text"
                                copyable={false}
                                render={(dom, entity, index, action) => {
                                    return (
                                        <div>
                                            {dom}
                                        </div>
                                    );
                                }}
                                renderFormItem={() => {
                                    return <ProFormText />;
                                }}
                            />
                            <ProDescriptions.Item
                                dataIndex={['projectScale']}
                                label="建设规模"
                                valueType="text"
                                copyable={false}
                                render={(dom, entity, index, action) => {
                                    return (
                                        <div>
                                            {dom}
                                        </div>
                                    );
                                }}
                                renderFormItem={() => {
                                    return <ProFormText />;
                                }}
                            />
                            <ProDescriptions.Item
                                dataIndex={['projectNature']}
                                label="建设性质"
                                valueType="text"
                                copyable={false}
                                render={(dom, entity, index, action) => {
                                    return (
                                        <div>
                                            {dom}
                                        </div>
                                    );
                                }}
                                renderFormItem={() => {
                                    return <ProFormText />;
                                }}
                            />
                            <ProDescriptions.Item
                                dataIndex={['projectPurpose']}
                                label="工程用途"
                                valueType="text"
                                copyable={false}
                                render={(dom, entity, index, action) => {
                                    return (
                                        <div>
                                            {dom}
                                        </div>
                                    );
                                }}
                                renderFormItem={() => {
                                    return <ProFormText />;
                                }}
                            />
                            <ProDescriptions.Item
                                dataIndex={['buildingLicenseNo']}
                                label="施工许可证号"
                                valueType="text"
                                copyable={false}
                                render={(dom, entity, index, action) => {
                                    return (
                                        <div>
                                            {dom}
                                        </div>
                                    );
                                }}
                                renderFormItem={() => {
                                    return <ProFormText />;
                                }}
                            />
                            <ProDescriptions.Item
                                dataIndex={['areaCode']}
                                label="地区代码"
                                valueType="text"
                                copyable={false}
                                render={(dom, entity, index, action) => {
                                    return (
                                        <div>
                                            {dom}
                                        </div>
                                    );
                                }}
                                renderFormItem={() => {
                                    return <ProFormText />;
                                }}
                            />
                            <ProDescriptions.Item
                                dataIndex={['landUsePlanNo']}
                                label="建设用地规划许可证编号"
                                valueType="text"
                                copyable={false}
                                render={(dom, entity, index, action) => {
                                    return (
                                        <div>
                                            {dom}
                                        </div>
                                    );
                                }}
                                renderFormItem={() => {
                                    return <ProFormText />;
                                }}
                            />
                            <ProDescriptions.Item
                                dataIndex={['projectPlanNo']}
                                label="建设工程规划许可证编号"
                                valueType="text"
                                copyable={false}
                                render={(dom, entity, index, action) => {
                                    return (
                                        <div>
                                            {dom}
                                        </div>
                                    );
                                }}
                                renderFormItem={() => {
                                    return <ProFormText />;
                                }}
                            />
                            <ProDescriptions.Item
                                dataIndex={['address']}
                                label="建设工程规划许可证编号"
                                valueType="text"
                                copyable={false}
                                render={(dom, entity, index, action) => {
                                    return (
                                        <div>
                                            {dom}
                                        </div>
                                    );
                                }}
                                renderFormItem={() => {
                                    return <ProFormText />;
                                }}
                            />
                            <ProDescriptions.Item
                                dataIndex={['contractor','primaryContractorCode']}
                                label="总承包单位统一社会信用代码"
                                valueType="text"
                                copyable={false}
                                render={(dom, entity, index, action) => {
                                    return (
                                        <div>
                                            {dom}
                                        </div>
                                    );
                                }}
                                renderFormItem={() => {
                                    return <ProFormText />;
                                }}
                            />
                            <ProDescriptions.Item
                                dataIndex={['contractor','primaryContractorName']}
                                label="总承包单位名称"
                                valueType="text"
                                copyable={false}
                                render={(dom, entity, index, action) => {
                                    return (
                                        <div>
                                            {dom}
                                        </div>
                                    );
                                }}
                                renderFormItem={() => {
                                    return <ProFormText />;
                                }}
                            />
                            <ProDescriptions.Item
                                dataIndex={['contractor','supervisoryContractorCode']}
                                label="监理单位统一社会信用代码"
                                valueType="text"
                                copyable={false}
                                render={(dom, entity, index, action) => {
                                    return (
                                        <div>
                                            {dom}
                                        </div>
                                    );
                                }}
                                renderFormItem={() => {
                                    return <ProFormText />;
                                }}
                            />
                            <ProDescriptions.Item
                                dataIndex={['contractor','supervisoryContractorName']}
                                label="监理单位名称"
                                valueType="text"
                                copyable={false}
                                render={(dom, entity, index, action) => {
                                    return (
                                        <div>
                                            {dom}
                                        </div>
                                    );
                                }}
                                renderFormItem={() => {
                                    return <ProFormText />;
                                }}
                            />
                            <ProDescriptions.Item
                                dataIndex={['leader','pmName']}
                                label="挂证项目经理姓名"
                                valueType="text"
                                copyable={false}
                                render={(dom, entity, index, action) => {
                                    return (
                                        <div>
                                            {dom}
                                        </div>
                                    );
                                }}
                                renderFormItem={() => {
                                    return <ProFormText />;
                                }}
                            />
                            <ProDescriptions.Item
                                dataIndex={['leader','pmMobile']}
                                label="挂证项目经理电话"
                                valueType="text"
                                copyable={false}
                                render={(dom, entity, index, action) => {
                                    return (
                                        <div>
                                            {dom}
                                        </div>
                                    );
                                }}
                                renderFormItem={() => {
                                    return <ProFormText />;
                                }}
                            />
                            <ProDescriptions.Item
                                dataIndex={['leader','realPmName']}
                                label="现场项目经理姓名"
                                valueType="text"
                                copyable={false}
                                render={(dom, entity, index, action) => {
                                    return (
                                        <div>
                                            {dom}
                                        </div>
                                    );
                                }}
                                renderFormItem={() => {
                                    return <ProFormText />;
                                }}
                            />
                            <ProDescriptions.Item
                                dataIndex={['leader','realPmMobile']}
                                label="现场项目经理电话"
                                valueType="text"
                                copyable={false}
                                render={(dom, entity, index, action) => {
                                    return (
                                        <div>
                                            {dom}
                                        </div>
                                    );
                                }}
                                renderFormItem={() => {
                                    return <ProFormText />;
                                }}
                            />
                            <ProDescriptions.Item
                                dataIndex={['leader','hrmName']}
                                label="实名制专员姓名"
                                valueType="text"
                                copyable={false}
                                render={(dom, entity, index, action) => {
                                    return (
                                        <div>
                                            {dom}
                                        </div>
                                    );
                                }}
                                renderFormItem={() => {
                                    return <ProFormText />;
                                }}
                            />
                            <ProDescriptions.Item
                                dataIndex={['leader','hrmMobile']}
                                label="实名制专员电话"
                                valueType="text"
                                copyable={false}
                                render={(dom, entity, index, action) => {
                                    return (
                                        <div>
                                            {dom}
                                        </div>
                                    );
                                }}
                                renderFormItem={() => {
                                    return <ProFormText />;
                                }}
                            />
                    </ProDescriptions>
                </Tabs.TabPane>

                <Tabs.TabPane tab="实名制推送设置" key="hrSyncSetting">
                    <GovPlatformTenantSettingPage
                        projectId={projectInfo?.id}
                    />
                </Tabs.TabPane>

                <Tabs.TabPane tab="IOT推送设置" key="iotSyncSetting">
                    <IotGovPlatformTenantSetting
                        projectId={projectInfo?.id}
                    />
                </Tabs.TabPane>
            </Tabs>
        </Drawer>
    );
};

export default ProjectInfoDetail;