import _, { result } from 'lodash';

import { DrawerProps, Space } from 'antd';
import type { ProDescriptionsActionType } from "@ant-design/pro-descriptions";

import React, { useRef, useState } from 'react';
import { Button, Drawer, message,   } from 'antd';
import ProDescriptions from '@ant-design/pro-descriptions';
import ProForm, {ProFormSwitch, ProFormText, } from '@ant-design/pro-form';
import { GovPlatformTenantSetting } from './../../type/GovPlatformTenantSettingType';
import GovPlatformTenantSettingApi from '../../api/GovPlatformTenantSettingApi';

export interface GovPlatformTenantSettingDetailProps extends DrawerProps {
    govPlatformTenantSetting?: GovPlatformTenantSetting,
    actionRef?: React.MutableRefObject<ProDescriptionsActionType | undefined>,
    onFail:()=>void,
    onSuccess:()=>void,
};

const GovPlatformTenantSettingDetail:React.FC<GovPlatformTenantSettingDetailProps> = (props)=>{

    const {govPlatformTenantSetting, onFail, onSuccess, actionRef} = props;
    const [title, setTitle] = useState<string|undefined>(govPlatformTenantSetting?.name);

    return (
        <Drawer
            title={title}
            width={960}
            closable={false}
            destroyOnClose={true}
            extra={[
                <Button
                        key='closeBtn'
                        type='primary'
                        style={{backgroundColor:'red', border:0}}
                        onClick={onFail}
                >
                    关闭
                </Button>
            ]}
            {..._.omit(props, 'onFail', 'onSuccess', 'actionRef')}
        >
            <ProDescriptions<GovPlatformTenantSetting>
                column={2}
                actionRef={actionRef}
                request={async ()=>{
                    const result:GovPlatformTenantSetting = await GovPlatformTenantSettingApi.findGovPlatformTenantSettingById(govPlatformTenantSetting?.id||"");
                    setTitle(result?.platform?.platformName);

                    return {
                        success: true,
                        data: result,
                    };
                }}
                editable={{
                    onSave:async (keyPath:any, newData, oldData)=>{
                        const requestData:any = {
                            id: oldData?.id
                        };
                        let rTemp = requestData;
                        let nTemp = newData;

                        for(let i=0; i<keyPath.length; i++){
                            let field = keyPath[i];

                            if(i===keyPath.length-1){
                                rTemp[field] = nTemp[field];
                            }else{
                                rTemp[field] = {};
                                rTemp = rTemp[field];
                                nTemp = nTemp[field];
                            }
                        }

                        await GovPlatformTenantSettingApi.patchEditGovPlatformTenantSetting(requestData);
                        actionRef?.current?.reload();
                        onSuccess();
                    }
                }}
            >
                    <ProDescriptions.Item
                        dataIndex={['platformId']}
                        label="平台"
                        valueType="text"
                        copyable={false}
                        render={(dom, entity, index, action) => {
                            return (
                                <div>
                                    {entity?.platform?.platformName}
                                </div>
                            );
                        }}
                        renderFormItem={() => {
                            return <ProFormText />;
                        }}
                    />
                    <ProDescriptions.Item
                        dataIndex={['syncManagerOnlyFlag']}
                        label="只上传关键岗位"
                        valueType="text"
                        copyable={false}
                        render={(dom, entity, index, action) => {
                            return (
                                <div>
                                    {entity?.syncManagerOnlyFlag}
                                </div>
                            );
                        }}
                        renderFormItem={() => {
                            return <ProFormSwitch />;
                        }}
                    />
                    <ProDescriptions.Item
                        dataIndex={['callbackAttendanceLogFlag']}
                        label="考勤记录回流"
                        valueType="text"
                        copyable={false}
                        render={(dom, entity, index, action) => {
                            return (
                                <div>
                                    {entity?.callbackAttendanceLogFlag}
                                </div>
                            );
                        }}
                        renderFormItem={() => {
                            return <ProFormSwitch />;
                        }}
                    />
                    <ProDescriptions.Item
                        dataIndex={['enabled']}
                        label="启用"
                        valueType="text"
                        copyable={false}
                        render={(dom, entity, index, action) => {
                            return (
                                <div>
                                     {entity?.enabled}
                                </div>
                            );
                        }}
                        renderFormItem={() => {
                            return <ProFormSwitch />;
                        }}
                    />
                    <ProDescriptions.Item
                        dataIndex={['appPlatformCode']}
                        label="应用平台编号"
                        valueType="text"
                        copyable={false}
                        render={(dom, entity, index, action) => {
                            return (
                                <div>
                                    {dom}
                                </div>
                            );
                        }}
                        renderFormItem={() => {
                            return <ProFormText />;
                        }}
                    />
                    <ProDescriptions.Item
                        dataIndex={['appId']}
                        label="APP ID"
                        valueType="text"
                        copyable={false}
                        render={(dom, entity, index, action) => {
                            return (
                                <div>
                                    {dom}
                                </div>
                            );
                        }}
                        renderFormItem={() => {
                            return <ProFormText />;
                        }}
                    />
                    <ProDescriptions.Item
                        dataIndex={['appKey']}
                        label="APP KEY"
                        valueType="text"
                        copyable={false}
                        render={(dom, entity, index, action) => {
                            return (
                                <div>
                                    {dom}
                                </div>
                            );
                        }}
                        renderFormItem={() => {
                            return <ProFormText />;
                        }}
                    />
                    <ProDescriptions.Item
                        dataIndex={['appSecret']}
                        label="APP Secret"
                        valueType="text"
                        copyable={false}
                        render={(dom, entity, index, action) => {
                            return (
                                <div>
                                    {dom}
                                </div>
                            );
                        }}
                        renderFormItem={() => {
                            return <ProFormText />;
                        }}
                    />
                    <ProDescriptions.Item 
                        span={2}
                        dataIndex={['appKey']}
                        editable={false}
                        render={(dom, entity, index, action) => {
                            return (
                                <Space>
                                    <Button type='primary' onClick={()=>{
                                        window.open(`/api/platform/hr-sync/redo/${entity?.platformId}/${entity?.tenantId}/all-except-attendance-log?Authorization=${localStorage.getItem("Authorization")}`);
                                    }}>
                                        同步所有
                                    </Button>
                                    <Button type='primary' onClick={()=>{
                                        window.open(`/api/platform/hr-sync/redo/${entity?.platformId}/${entity?.tenantId}/project-info?Authorization=${localStorage.getItem("Authorization")}`);
                                    }}>
                                        同步项目
                                    </Button>
                                    <Button type='primary' onClick={()=>{
                                        window.open(`/api/platform/hr-sync/redo/${entity?.platformId}/${entity?.tenantId}/contractor?Authorization=${localStorage.getItem("Authorization")}`);
                                    }}>
                                        同步参见单位
                                    </Button>
                                    <Button type='primary' onClick={()=>{
                                        window.open(`/api/platform/hr-sync/redo/${entity?.platformId}/${entity?.tenantId}/work-team?Authorization=${localStorage.getItem("Authorization")}`);
                                    }}>
                                        同步班组
                                    </Button>
                                    <Button type='primary' onClick={()=>{
                                        window.open(`/api/platform/hr-sync/redo/${entity?.platformId}/${entity?.tenantId}/employee?Authorization=${localStorage.getItem("Authorization")}`);
                                    }}>
                                        同步人员
                                    </Button>
                                    <Button type='primary' onClick={()=>{
                                        window.open(`/api/platform/hr-sync/redo/${entity?.platformId}/${entity?.tenantId}/employee-entry?Authorization=${localStorage.getItem("Authorization")}`);
                                    }}>
                                        同步人员入职
                                    </Button>
                                    <Button type='primary' onClick={()=>{
                                        window.open(`/api/platform/hr-sync/redo/${entity?.platformId}/${entity?.tenantId}/employee-exit?Authorization=${localStorage.getItem("Authorization")}`);
                                    }}>
                                        同步人员离职
                                    </Button>
                                    <Button type='primary' onClick={()=>{
                                        window.open(`/api/platform/hr-sync/redo/${entity?.platformId}/${entity?.tenantId}/attendance-log?Authorization=${localStorage.getItem("Authorization")}`);
                                    }}>
                                        同步考勤记录
                                    </Button>
                                    {
                                        entity.platformId=='1136096956834119680' && 
                                        <Button type='primary' onClick={()=>{
                                            window.open(`/api/platform/hr-sync/p330800-direct-employee/${entity.id}?Authorization=${localStorage.getItem("Authorization")}`);
                                        }}>
                                            同步浙里建人员
                                        </Button>
                                    }
                                </Space>
                            );
                        }}>
                        
                </ProDescriptions.Item>
            </ProDescriptions>

            <div>
                
            </div>
        </Drawer>
    );
};

export default GovPlatformTenantSettingDetail;