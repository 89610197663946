import _ from 'lodash';

import type { DrawerProps } from 'antd';
import type { ProDescriptionsActionType } from "@ant-design/pro-descriptions";
import type {ProFormProps} from '@ant-design/pro-form';

import React, { useRef } from 'react';
import {Button, Drawer, Space, Row, Col, Form, } from 'antd';
import {FooterToolbar} from '@ant-design/pro-layout';
import ProForm, {ModalForm, DrawerForm, ProFormText, } from '@ant-design/pro-form';

import FormLayout from './../../../../utils/ui/layout/FormLayout';

import { OutsideElevatorLog } from './../../type/OutsideElevatorLogType';
import OutsideElevatorLogApi from '../../api/OutsideElevatorLogApi';
import { message } from 'antd';

export interface OutsideElevatorLogAddFormProps extends DrawerProps {
    onFail:()=>void,
    onSuccess:()=>void,
};



const OutsideElevatorLogAddForm:React.FC<OutsideElevatorLogAddFormProps> = (props)=>{

    const [form] = Form.useForm<OutsideElevatorLog>();
    const {onFail, onSuccess} = props;

    return (
        <Drawer
            title="新建升降机原始记录"
            destroyOnClose={false}
            closable={false}
            width={960}
            extra={[
                <Button
                    key='closeBtn'
                    type='primary'
                    style={{backgroundColor:'red', border:0}}
                    onClick={onFail}
                >
                    关闭
                </Button>
            ]}
            {..._.omit(props, 'onFail', 'onSuccess')}
        >
            <ProForm
                submitter={{
                    render: (_, dom) => <div style={{textAlign:'right', marginTop:16}}><Space>{dom}</Space></div>,
                }}
                onFinish={async (values:  OutsideElevatorLog)=>{
                    await OutsideElevatorLogApi.addOutsideElevatorLog(values);
                    form.resetFields();
                    message.success("新建升降机原始记录成功！");
                    if(onSuccess){
                        onSuccess();
                    }else{
                        onFail();
                    }
                    return true;
                }}
                form={form}
                layout={'horizontal'}
            >
                <Row>
                    <Col span={12}>
                        <ProFormText
                            name={['outKey']}
                            label="外部记录ID"
                            tooltip=""
                            placeholder=""
                            rules={[{required:false, message: "外部记录ID不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormText
                            name={['deviceSn']}
                            label="设备序列号"
                            tooltip=""
                            placeholder=""
                            rules={[{required:false, message: "设备序列号不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormText
                            name={['deviceTime']}
                            label="设备时间"
                            tooltip=""
                            placeholder=""
                            rules={[{required:false, message: "设备时间不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormText
                            name={['load']}
                            label="载重"
                            tooltip=""
                            placeholder=""
                            rules={[{required:false, message: "载重不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormText
                            name={['loadPercent']}
                            label="载重百分比"
                            tooltip=""
                            placeholder=""
                            rules={[{required:false, message: "载重百分比不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormText
                            name={['peopleCount']}
                            label="人数"
                            tooltip=""
                            placeholder=""
                            rules={[{required:false, message: "人数不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormText
                            name={['windSpeed']}
                            label="风速"
                            tooltip=""
                            placeholder=""
                            rules={[{required:false, message: "风速不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormText
                            name={['height']}
                            label="高度"
                            tooltip=""
                            placeholder=""
                            rules={[{required:false, message: "高度不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormText
                            name={['speed']}
                            label="升降机运行速度"
                            tooltip=""
                            placeholder=""
                            rules={[{required:false, message: "升降机运行速度不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormText
                            name={['totalMileage']}
                            label="升降机运行里程"
                            tooltip=""
                            placeholder=""
                            rules={[{required:false, message: "升降机运行里程不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormText
                            name={['slantAngel']}
                            label="倾斜角度"
                            tooltip=""
                            placeholder=""
                            rules={[{required:false, message: "倾斜角度不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormText
                            name={['callFloor']}
                            label="呼叫楼层"
                            tooltip=""
                            placeholder=""
                            rules={[{required:false, message: "呼叫楼层不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormText
                            name={['currentFloor']}
                            label="当前楼层"
                            tooltip=""
                            placeholder=""
                            rules={[{required:false, message: "当前楼层不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormText
                            name={['limitSwitch']}
                            label="限位开关"
                            tooltip=""
                            placeholder=""
                            rules={[{required:false, message: "限位开关不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormText
                            name={['runningState']}
                            label="限位开关"
                            tooltip=""
                            placeholder=""
                            rules={[{required:false, message: "限位开关不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormText
                            name={['bypass']}
                            label="Bypass开关"
                            tooltip=""
                            placeholder=""
                            rules={[{required:false, message: "Bypass开关不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormText
                            name={['totalRunDays']}
                            label="总运行天数"
                            tooltip=""
                            placeholder=""
                            rules={[{required:false, message: "总运行天数不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormText
                            name={['firstRunDate']}
                            label="首次运行日期"
                            tooltip=""
                            placeholder=""
                            rules={[{required:false, message: "首次运行日期不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormText
                            name={['openTime']}
                            label="本次开机时间"
                            tooltip=""
                            placeholder=""
                            rules={[{required:false, message: "本次开机时间不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormText
                            name={['driverId']}
                            label="司机ID"
                            tooltip=""
                            placeholder=""
                            rules={[{required:false, message: "司机ID不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormText
                            name={['certNo']}
                            label="作业证号"
                            tooltip=""
                            placeholder=""
                            rules={[{required:false, message: "作业证号不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormText
                            name={['elevatorLogId']}
                            label="养成记录ID"
                            tooltip=""
                            placeholder=""
                            rules={[{required:false, message: "养成记录ID不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormText
                            name={['createdTime']}
                            label="创建时间"
                            tooltip=""
                            placeholder=""
                            rules={[{required:false, message: "创建时间不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormText
                            name={['updatedTime']}
                            label="更新时间"
                            tooltip=""
                            placeholder=""
                            rules={[{required:false, message: "更新时间不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                </Row>
            </ProForm>
        </Drawer>
    );
};

export default OutsideElevatorLogAddForm;