
import {TowerCraneMachinePlatform, TowerCraneMachinePlatformQto} from './../type/TowerCraneMachinePlatformType';
import HttpInvoker from './../../../utils/http/HttpInvoker';


const addTowerCraneMachinePlatform = async (params:TowerCraneMachinePlatform)=>{

    return HttpInvoker.postObject(`/api/tenant/iot/tower-crane-machine-platform`, params);
};


const editTowerCraneMachinePlatform = async (params:TowerCraneMachinePlatform)=>{

    return HttpInvoker.putObject(`/api/tenant/iot/tower-crane-machine-platform/${params.id}`, params);
};


const patchEditTowerCraneMachinePlatform = async (params:TowerCraneMachinePlatform)=>{

    return HttpInvoker.patchObject(`/api/tenant/iot/tower-crane-machine-platform/${params.id}`, params);
};


const deleteTowerCraneMachinePlatform = async (params:TowerCraneMachinePlatform)=>{

    return HttpInvoker.deleteObject(`/api/tenant/iot/tower-crane-machine-platform/${params.id}`, params);
};


const findTowerCraneMachinePlatformById = async (params:string)=>{

    return HttpInvoker.getObject(`/api/tenant/iot/tower-crane-machine-platform/${params}`, params);
};

const findTowerCraneMachinePlatformList = async (params:any)=>{

    return HttpInvoker.getObject(`/api/tenant/iot/tower-crane-machine-platform/list`, params);
};

const findTowerCraneMachinePlatformPage = async (params:any)=>{

    return HttpInvoker.getObject(`/api/tenant/iot/tower-crane-machine-platform/page`, params);
};



export default {
    addTowerCraneMachinePlatform,
    editTowerCraneMachinePlatform,
    patchEditTowerCraneMachinePlatform,
    deleteTowerCraneMachinePlatform,
    findTowerCraneMachinePlatformById,
    findTowerCraneMachinePlatformList,
    findTowerCraneMachinePlatformPage,
};


