
import {DustMachinePlatform, DustMachinePlatformQto} from './../type/DustMachinePlatformType';
import HttpInvoker from './../../../utils/http/HttpInvoker';


const addDustMachinePlatform = async (params:DustMachinePlatform)=>{

    return HttpInvoker.postObject(`/api/tenant/iot/dust-machine-platform`, params);
};


const editDustMachinePlatform = async (params:DustMachinePlatform)=>{

    return HttpInvoker.putObject(`/api/tenant/iot/dust-machine-platform/${params.id}`, params);
};


const patchEditDustMachinePlatform = async (params:DustMachinePlatform)=>{

    return HttpInvoker.patchObject(`/api/tenant/iot/dust-machine-platform/${params.id}`, params);
};


const deleteDustMachinePlatform = async (params:DustMachinePlatform)=>{

    return HttpInvoker.deleteObject(`/api/tenant/iot/dust-machine-platform/${params.id}`, params);
};


const findDustMachinePlatformById = async (params:string)=>{

    return HttpInvoker.getObject(`/api/tenant/iot/dust-machine-platform/${params}`, params);
};

const findDustMachinePlatformList = async (params:any)=>{

    return HttpInvoker.getObject(`/api/tenant/iot/dust-machine-platform/list`, params);
};

const findDustMachinePlatformPage = async (params:any)=>{

    return HttpInvoker.getObject(`/api/tenant/iot/dust-machine-platform/page`, params);
};



export default {
    addDustMachinePlatform,
    editDustMachinePlatform,
    patchEditDustMachinePlatform,
    deleteDustMachinePlatform,
    findDustMachinePlatformById,
    findDustMachinePlatformList,
    findDustMachinePlatformPage,
};


