import _ from 'lodash';

import type { DrawerProps } from 'antd';
import type { ProDescriptionsActionType } from "@ant-design/pro-descriptions";
import type {ProFormProps} from '@ant-design/pro-form';

import React, { useRef } from 'react';
import {Button, Drawer, Space, Row, Col, Form, } from 'antd';
import {FooterToolbar} from '@ant-design/pro-layout';
import ProForm, {ModalForm, DrawerForm, ProFormText, ProFormDatePicker, ProFormSwitch, } from '@ant-design/pro-form';

import FormLayout from './../../../../utils/ui/layout/FormLayout';

import { Person } from './../../type/PersonType';
import PersonApi from '../../api/PersonApi';
import { message } from 'antd';
import SingleFileSelector from '../../../file/component/SingleFileSelector';

export interface PersonAddFormProps extends DrawerProps {
    onFail:()=>void,
    onSuccess:()=>void,
};



const PersonAddForm:React.FC<PersonAddFormProps> = (props)=>{

    const [form] = Form.useForm<Person>();
    const {onFail, onSuccess} = props;

    return (
        <Drawer
            title="新建人员信息"
            destroyOnClose={false}
            closable={false}
            width={960}
            extra={[
                <Button
                    key='closeBtn'
                    type='primary'
                    style={{backgroundColor:'red', border:0}}
                    onClick={onFail}
                >
                    关闭
                </Button>
            ]}
            {..._.omit(props, 'onFail', 'onSuccess')}
        >
            <ProForm
                submitter={{
                    render: (_, dom) => <div style={{textAlign:'right', marginTop:16}}><Space>{dom}</Space></div>,
                }}
                onFinish={async (values:  Person)=>{
                    await PersonApi.addPerson(values);
                    form.resetFields();
                    message.success("新建人员信息成功！");
                    if(onSuccess){
                        onSuccess();
                    }else{
                        onFail();
                    }
                    return true;
                }}
                form={form}
                layout={'horizontal'}
            >
                <Row>
                    <Col span={12}>
                        <ProFormText
                            name={['mobile']}
                            label="手机号"
                            tooltip=""
                            placeholder=""
                            rules={[{required:true, message: "手机号不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormText
                            name={['idCardNo']}
                            label="身份证号码"
                            tooltip=""
                            placeholder=""
                            rules={[{required:true, message: "身份证号码不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormText
                            name={['name']}
                            label="姓名"
                            tooltip=""
                            placeholder=""
                            rules={[{required:true, message: "姓名不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormText
                            name={['sex']}
                            label="性别"
                            tooltip=""
                            placeholder=""
                            rules={[{required:true, message: "性别不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormText
                            name={['nation']}
                            label="民族"
                            tooltip=""
                            placeholder=""
                            rules={[{required:true, message: "民族不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormDatePicker
                            name={['birthday']}
                            label="生日"
                            tooltip=""
                            placeholder=""
                            rules={[{required:true, message: "生日不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormText
                            name={['idCardIssuedBy']}
                            label="签发机关"
                            tooltip=""
                            placeholder=""
                            rules={[{required:true, message: "签发机关不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormDatePicker
                            name={['idCardBeginDate']}
                            label="有效期开始日期"
                            tooltip=""
                            placeholder=""
                            rules={[{required:true, message: "有效期开始日期不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormDatePicker
                            name={['idCardEndDate']}
                            label="有效期结束日期"
                            tooltip=""
                            placeholder=""
                            rules={[{required:true, message: "有效期结束日期不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormSwitch
                            name={['idCardNoExpirationFlag']}
                            label="长期有效标记"
                            tooltip=""
                            placeholder=""
                            rules={[{required:true, message: "长期有效标记不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={24}>
                        <ProFormText
                            name={['idCardAddress']}
                            label="证件地址"
                            tooltip=""
                            placeholder=""
                            rules={[{required:true, message: "证件地址不能为空"}]}
                            {...FormLayout.itemLayout.span24}
                        />
                    </Col>
                    <Col span={8}>
                        <SingleFileSelector
                            name={['idCardAvatarImageUrl']}
                            label="身份证头像URL"
                            tooltip=""
                            placeholder=""
                            rules={[{required:true, message: "身份证头像URL不能为空"}]}
                            {...FormLayout.itemLayout.span8}
                        />
                    </Col>
                    <Col span={8}>
                        <SingleFileSelector
                            name={['idCardFrontImageUrl']}
                            label="身份证正面照片URL"
                            tooltip=""
                            placeholder=""
                            rules={[{required:true, message: "身份证正面照片URL不能为空"}]}
                            {...FormLayout.itemLayout.span8}
                        />
                    </Col>
                    <Col span={8}>
                        <SingleFileSelector
                            name={['idCardBackImageUrl']}
                            label="身份证背面照片URL"
                            tooltip=""
                            placeholder=""
                            rules={[{required:true, message: "身份证背面照片URL不能为空"}]}
                            {...FormLayout.itemLayout.span8}
                        />
                    </Col>
                </Row>
            </ProForm>
        </Drawer>
    );
};

export default PersonAddForm;