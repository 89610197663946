import _ from 'lodash';

import type { DrawerProps } from 'antd';
import type { ProDescriptionsActionType } from "@ant-design/pro-descriptions";

import React, { useRef, useState } from 'react';
import { Button, Drawer, message,   } from 'antd';
import ProDescriptions from '@ant-design/pro-descriptions';
import ProForm, {ProFormDatePicker, ProFormText, } from '@ant-design/pro-form';
import { ElevatorMachineMetaInfo } from './../../type/ElevatorMachineMetaInfoType';
import ElevatorMachineMetaInfoApi from '../../api/ElevatorMachineMetaInfoApi';
import DictItemDisplay from '../../../system/pages/DictItem/DictItemDisplay';
import DictItemSelector from '../../../system/pages/DictItem/DictItemSelector';
import ElevatorMachinePlatformSelector from '../ElevatorMachinePlatform/ElevatorMachinePlatformSelector';
import CompanyModalSelector from '../../../jz/pages/Company/CompanyModalSelector';

export interface ElevatorMachineMetaInfoDetailProps extends DrawerProps {
    elevatorMachineMetaInfo?: ElevatorMachineMetaInfo,
    actionRef?: React.MutableRefObject<ProDescriptionsActionType | undefined>,
    onFail:()=>void,
    onSuccess:()=>void,
};

const ElevatorMachineMetaInfoDetail:React.FC<ElevatorMachineMetaInfoDetailProps> = (props)=>{

    const {elevatorMachineMetaInfo, onFail, onSuccess, actionRef} = props;
    const [title, setTitle] = useState<string|undefined>(elevatorMachineMetaInfo?.sn);

    return (
        <Drawer
            title={title}
            width={960}
            closable={false}
            destroyOnClose={true}
            extra={[
                <Button
                        key='closeBtn'
                        type='primary'
                        style={{backgroundColor:'red', border:0}}
                        onClick={onFail}
                >
                    关闭
                </Button>
            ]}
            {..._.omit(props, 'onFail', 'onSuccess', 'actionRef')}
        >
            <ProDescriptions<ElevatorMachineMetaInfo>
                column={2}
                actionRef={actionRef}
                request={async ()=>{
                    const result:ElevatorMachineMetaInfo = await ElevatorMachineMetaInfoApi.findElevatorMachineMetaInfoById(elevatorMachineMetaInfo?.id||"");
                    setTitle(result?.sn);

                    return {
                        success: true,
                        data: result,
                    };
                }}
                editable={{
                    onSave:async (keyPath:any, newData, oldData)=>{
                        const requestData:any = {
                            id: oldData?.id
                        };
                        let rTemp = requestData;
                        let nTemp = newData;

                        for(let i=0; i<keyPath.length; i++){
                            let field = keyPath[i];

                            if(i===keyPath.length-1){
                                rTemp[field] = nTemp[field];
                            }else{
                                rTemp[field] = {};
                                rTemp = rTemp[field];
                                nTemp = nTemp[field];
                            }
                        }

                        await ElevatorMachineMetaInfoApi.patchEditElevatorMachineMetaInfo(requestData);
                        actionRef?.current?.reload();
                        onSuccess();
                    }
                }}
            >
                    <ProDescriptions.Item
                        dataIndex={['sn']}
                        label="序列号"
                        valueType="text"
                        copyable={false}
                        render={(dom, entity, index, action) => {
                            return (
                                <div>
                                    {dom}
                                </div>
                            );
                        }}
                        renderFormItem={() => {
                            return <ProFormText />;
                        }}
                    />
                    <ProDescriptions.Item
                        dataIndex={['brand']}
                        label="品牌"
                        valueType="text"
                        copyable={false}
                        render={(dom, entity, index, action) => {
                            return (
                                <div>
                                    <DictItemDisplay
                                        entryCode='IOT_DUST_MACHINE_BRAND'
                                        value={dom?.toString()||""}
                                    />
                                </div>
                            );
                        }}
                        renderFormItem={() => {
                            return (
                                <DictItemSelector 
                                    entryCode='IOT_DUST_MACHINE_BRAND'
                                />
                            );
                        }}
                    />
                    <ProDescriptions.Item
                        dataIndex={['type']}
                        label="型号"
                        valueType="text"
                        copyable={false}
                        render={(dom, entity, index, action) => {
                            return (
                                <div>
                                    <DictItemDisplay
                                        entryCode='IOT_LED_TYPE'
                                        value={dom?.toString()||""}
                                    />
                                </div>
                            );
                        }}
                        renderFormItem={() => {
                            return (
                                <DictItemSelector
                                    entryCode='IOT_LED_TYPE'
                                />
                            );
                        }}
                    />
                    <ProDescriptions.Item
                        dataIndex={['platformId']}
                        label="平台"
                        valueType="text"
                        copyable={false}
                        render={(dom, entity, index, action) => {
                            return (
                                <div>
                                    {entity?.platform?.name}
                                </div>
                            );
                        }}
                        renderFormItem={() => {
                            return <ElevatorMachinePlatformSelector />;
                        }}
                    />
                    <ProDescriptions.Item
                        dataIndex={['supplierId']}
                        label="供应商"
                        valueType="text"
                        copyable={false}
                        render={(dom, entity, index, action) => {
                            return (
                                <div>
                                    {dom}
                                </div>
                            );
                        }}
                        renderFormItem={() => {
                            return <CompanyModalSelector />;
                        }}
                    />
                    <ProDescriptions.Item
                        dataIndex={['agentId']}
                        label="代理商"
                        valueType="text"
                        copyable={false}
                        render={(dom, entity, index, action) => {
                            return (
                                <div>
                                    {dom}
                                </div>
                            );
                        }}
                        renderFormItem={() => {
                            return <CompanyModalSelector />;
                        }}
                    />
                    <ProDescriptions.Item
                        dataIndex={['buyerId']}
                        label="采购商"
                        valueType="text"
                        copyable={false}
                        render={(dom, entity, index, action) => {
                            return (
                                <div>
                                    {dom}
                                </div>
                            );
                        }}
                        renderFormItem={() => {
                            return <CompanyModalSelector />;
                        }}
                    />
                    <ProDescriptions.Item
                        dataIndex={['purchaseDate']}
                        label="采购日期"
                        valueType="text"
                        copyable={false}
                        render={(dom, entity, index, action) => {
                            return (
                                <div>
                                    {dom}
                                </div>
                            );
                        }}
                        renderFormItem={() => {
                            return <ProFormDatePicker />;
                        }}
                    />
            </ProDescriptions>
        </Drawer>
    );
};

export default ElevatorMachineMetaInfoDetail;