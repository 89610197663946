import React, {useState, useEffect} from "react";

import type {SelectProps} from 'antd';
import { Select } from "antd";
import AttendanceMachinePlatformApi from "../../api/AttendanceMachinePlatformApi";
import type { AttendanceMachinePlatform } from "../../type/AttendanceMachinePlatformType";
import { ProFormSelectProps } from "@ant-design/pro-form/lib/components/Select";
import { ProFormSelect } from "@ant-design/pro-form";

const AttendanceMachinePlatformSelector:React.FC<ProFormSelectProps> = (props)=>{

    const [options, setOptions] = useState<[]>([]);

    useEffect(()=>{
        AttendanceMachinePlatformApi.findAttendanceMachinePlatformList({
            limit: 100,
            deletedFlag: false,
        }).then((result:any)=>{
            const newOptions = result.map((attendanceMachinePlatform:AttendanceMachinePlatform)=>{
                return {
                    key: attendanceMachinePlatform.id,
                    value: attendanceMachinePlatform.id,
                    label: attendanceMachinePlatform.name,
                };
            });

            setOptions(newOptions);
        });
    }, []);

    return (
        <ProFormSelect
            options={options}
            {...props}
        />
    );
};

export default AttendanceMachinePlatformSelector;