import _ from 'lodash';

import type { DrawerProps } from 'antd';
import type { ProDescriptionsActionType } from "@ant-design/pro-descriptions";
import type {ProFormProps} from '@ant-design/pro-form';

import React, { useRef } from 'react';
import {Button, Drawer, Space, Row, Col, Form, } from 'antd';
import {FooterToolbar} from '@ant-design/pro-layout';
import ProForm, {ModalForm, DrawerForm, ProFormText, } from '@ant-design/pro-form';

import FormLayout from './../../../../utils/ui/layout/FormLayout';

import { ProjectInfo } from './../../type/ProjectInfoType';
import ProjectInfoApi from '../../api/ProjectInfoApi';
import { message } from 'antd';

export interface ProjectInfoAddFormProps extends DrawerProps {
    onFail:()=>void,
    onSuccess:()=>void,
};



const ProjectInfoAddForm:React.FC<ProjectInfoAddFormProps> = (props)=>{

    const [form] = Form.useForm<ProjectInfo>();
    const {onFail, onSuccess} = props;

    return (
        <Drawer
            title="新建项目工程"
            destroyOnClose={false}
            closable={false}
            width={960}
            extra={[
                <Button
                    key='closeBtn'
                    type='primary'
                    style={{backgroundColor:'red', border:0}}
                    onClick={onFail}
                >
                    关闭
                </Button>
            ]}
            {..._.omit(props, 'onFail', 'onSuccess')}
        >
            <ProForm
                submitter={{
                    render: (_, dom) => <div style={{textAlign:'right', marginTop:16}}><Space>{dom}</Space></div>,
                }}
                onFinish={async (values:  ProjectInfo)=>{
                    await ProjectInfoApi.addProjectInfo(values);
                    form.resetFields();
                    message.success("新建项目工程成功！");
                    if(onSuccess){
                        onSuccess();
                    }else{
                        onFail();
                    }
                    return true;
                }}
                form={form}
                layout={'horizontal'}
            >
                <Row>
                    <Col span={24}>
                        <ProFormText
                            name={['name']}
                            label="工程名称"
                            tooltip=""
                            placeholder=""
                            rules={[{required:true, message: "工程名称不能为空"}]}
                            {...FormLayout.itemLayout.span24}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormText
                            name={['code']}
                            label="项目代码"
                            tooltip=""
                            placeholder=""
                            rules={[{required:true, message: "项目代码不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormText
                            name={['status']}
                            label="工程状态"
                            tooltip=""
                            placeholder=""
                            rules={[{required:true, message: "工程状态不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormText
                            name={['category']}
                            label="项目分类"
                            tooltip=""
                            placeholder=""
                            rules={[{required:false, message: "项目分类不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormText
                            name={['approvalLevel']}
                            label="立项级别"
                            tooltip=""
                            placeholder=""
                            rules={[{required:false, message: "立项级别不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormText
                            name={['projectScale']}
                            label="建设规模"
                            tooltip=""
                            placeholder=""
                            rules={[{required:false, message: "建设规模不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormText
                            name={['projectNature']}
                            label="建设性质"
                            tooltip=""
                            placeholder=""
                            rules={[{required:false, message: "建设性质不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormText
                            name={['projectPurpose']}
                            label="工程用途"
                            tooltip=""
                            placeholder=""
                            rules={[{required:false, message: "工程用途不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormText
                            name={['buildingLicenseNo']}
                            label="施工许可证号"
                            tooltip=""
                            placeholder=""
                            rules={[{required:false, message: "施工许可证号不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormText
                            name={['areaCode']}
                            label="地区代码"
                            tooltip=""
                            placeholder=""
                            rules={[{required:true, message: "地区代码不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormText
                            name={['landUsePlanNo']}
                            label="建设用地规划许可证编号"
                            tooltip=""
                            placeholder=""
                            rules={[{required:false, message: "建设用地规划许可证编号不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormText
                            name={['projectPlanNo']}
                            label="建设工程规划许可证编号"
                            tooltip=""
                            placeholder=""
                            rules={[{required:false, message: "建设工程规划许可证编号不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormText
                            name={['address']}
                            label="建设工程规划许可证编号"
                            tooltip=""
                            placeholder=""
                            rules={[{required:false, message: "建设工程规划许可证编号不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormText
                            name={['contractor','primaryContractorCode']}
                            label="总承包单位统一社会信用代码"
                            tooltip=""
                            placeholder=""
                            rules={[{required:true, message: "总承包单位统一社会信用代码不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormText
                            name={['contractor','primaryContractorName']}
                            label="总承包单位名称"
                            tooltip=""
                            placeholder=""
                            rules={[{required:true, message: "总承包单位名称不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormText
                            name={['contractor','supervisoryContractorCode']}
                            label="监理单位统一社会信用代码"
                            tooltip=""
                            placeholder=""
                            rules={[{required:true, message: "监理单位统一社会信用代码不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormText
                            name={['contractor','supervisoryContractorName']}
                            label="监理单位名称"
                            tooltip=""
                            placeholder=""
                            rules={[{required:true, message: "监理单位名称不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormText
                            name={['leader','pmName']}
                            label="挂证项目经理姓名"
                            tooltip=""
                            placeholder=""
                            rules={[{required:false, message: "挂证项目经理姓名不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormText
                            name={['leader','pmMobile']}
                            label="挂证项目经理电话"
                            tooltip=""
                            placeholder=""
                            rules={[{required:false, message: "挂证项目经理电话不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormText
                            name={['leader','realPmName']}
                            label="现场项目经理姓名"
                            tooltip=""
                            placeholder=""
                            rules={[{required:false, message: "现场项目经理姓名不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormText
                            name={['leader','realPmMobile']}
                            label="现场项目经理电话"
                            tooltip=""
                            placeholder=""
                            rules={[{required:false, message: "现场项目经理电话不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormText
                            name={['leader','hrmName']}
                            label="实名制专员姓名"
                            tooltip=""
                            placeholder=""
                            rules={[{required:true, message: "实名制专员姓名不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormText
                            name={['leader','hrmMobile']}
                            label="实名制专员电话"
                            tooltip=""
                            placeholder=""
                            rules={[{required:true, message: "实名制专员电话不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                </Row>
            </ProForm>
        </Drawer>
    );
};

export default ProjectInfoAddForm;