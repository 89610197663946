import React from 'react';
import { stringify } from 'qs';
import 'whatwg-fetch';
import {Modal} from 'antd';

const codeMessage:any = {
    200: '服务器成功返回请求的数据。',
    201: '新建或修改数据成功。',
    202: '一个请求已经进入后台排队（异步任务）。',
    204: '删除数据成功。',
    400: '发出的请求有错误，服务器没有进行新建或修改数据的操作。',
    401: '用户没有权限（令牌、用户名、密码错误）。',
    403: '用户得到授权，但是访问是被禁止的。',
    404: '服务器地址不存在，服务器没有进行操作。',
    406: '请求的格式不可得。',
    410: '请求的资源被永久删除，且不会再得到的。',
    422: '当创建一个对象时，发生一个验证错误。',
    500: '服务器发生错误，请检查服务器。',
    502: '网关错误。',
    503: '服务不可用，服务器暂时过载或维护。',
    504: '网关超时。',
};

const checkStatus = (response:any) => {

    if(response.status>=200 && response.status<300){
        return response;
    }

    const error:any = new Error();

    error.status = response.status;
    error.message = codeMessage[response.status];

    Modal.error({
        title: '系统错误' + error.status,
        content: error.message,
    });

    throw error;
}

const jsonHeaderOptions = {
    'Accept': 'application/json',
    'Content-Type': 'application/json; charset=utf-8',
    'Authorization': localStorage.getItem('Authorization')||"",
};

const processJsonResult = (text:any)=>{

    if(text){
        let responseResult = JSON.parse(text);
        if(responseResult.success===true){
            return responseResult.data;
        }else{
            const error:any = new Error();
            
            error.status = responseResult.code;
            error.message = responseResult.message;

            Modal.error({
                title: '系统错误',
                content: error.message,
            });

            throw error;
        }
    }
}

const postObject = (url:string, data:any) => {
    
    return fetch(url, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: jsonHeaderOptions,
    })
    .then(checkStatus)
    .then(response=>response.text())
    .then(processJsonResult)
};

const putObject = (url:string, data:any) => {
    
    return fetch(url, {
        method: 'PUT',
        body: JSON.stringify(data),
        headers: jsonHeaderOptions,
    })
    .then(checkStatus)
    .then(response=>response.text())
    .then(processJsonResult)
};

const patchObject = (url:string, data:any) => {
    
    return fetch(url, {
        method: 'PATCH',
        body: JSON.stringify(data),
        headers: jsonHeaderOptions,
    })
    .then(checkStatus)
    .then(response=>response.text())
    .then(processJsonResult)
};

const deleteObject = (url:string, data:any) => {
    return fetch(`${url}?${stringify(data)}`, {
        method: 'DELETE',
        headers: jsonHeaderOptions,
    })
    .then(checkStatus)
    .then(response=>response.text())
    .then(processJsonResult)
};

const getObject = (url:string, data:any) => {

    return fetch(`${url}?${stringify(data)}`, {
        method: 'GET',
        headers: jsonHeaderOptions,
    })
    .then(checkStatus)
    .then(response=>response.text())
    .then(processJsonResult)
};

const uploadFile = (url:string, file:any) => {

};

const downloadFile = (url:string, data:any) => {

};

const exchangeFile = (url:string, file:any) => {

}

export default {
    postObject,
    putObject,
    patchObject,
    deleteObject,
    getObject,
    uploadFile,
    downloadFile,
    exchangeFile,
}