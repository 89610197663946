import _ from 'lodash';

import type { DrawerProps } from 'antd';
import type { ProDescriptionsActionType } from "@ant-design/pro-descriptions";
import type {ProFormProps} from '@ant-design/pro-form';

import React, { useRef } from 'react';
import {Button, Drawer, Space, Row, Col, Form, } from 'antd';
import {FooterToolbar} from '@ant-design/pro-layout';
import ProForm, {ModalForm, DrawerForm, ProFormText, ProFormSwitch, } from '@ant-design/pro-form';

import FormLayout from './../../../../utils/ui/layout/FormLayout';

import { IotGovPlatform } from './../../type/IotGovPlatformType';
import IotGovPlatformApi from '../../api/IotGovPlatformApi';
import { message } from 'antd';

export interface IotGovPlatformAddFormProps extends DrawerProps {
    onFail:()=>void,
    onSuccess:()=>void,
};



const IotGovPlatformAddForm:React.FC<IotGovPlatformAddFormProps> = (props)=>{

    const [form] = Form.useForm<IotGovPlatform>();
    const {onFail, onSuccess} = props;

    return (
        <Drawer
            title="新建政府平台"
            destroyOnClose={false}
            closable={false}
            width={960}
            extra={[
                <Button
                    key='closeBtn'
                    type='primary'
                    style={{backgroundColor:'red', border:0}}
                    onClick={onFail}
                >
                    关闭
                </Button>
            ]}
            {..._.omit(props, 'onFail', 'onSuccess')}
        >
            <ProForm
                submitter={{
                    render: (_, dom) => <div style={{textAlign:'right', marginTop:16}}><Space>{dom}</Space></div>,
                }}
                onFinish={async (values:  IotGovPlatform)=>{
                    await IotGovPlatformApi.addIotGovPlatform(values);
                    form.resetFields();
                    message.success("新建政府平台成功！");
                    if(onSuccess){
                        onSuccess();
                    }else{
                        onFail();
                    }
                    return true;
                }}
                form={form}
                layout={'horizontal'}
            >
                <Row>
                    <Col span={12}>
                        <ProFormText
                            name={['govName']}
                            label="监管部门名称"
                            tooltip=""
                            placeholder=""
                            rules={[{required:true, message: "监管部门名称不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormText
                            name={['govAreaCode']}
                            label="监管地区代码"
                            tooltip=""
                            placeholder=""
                            rules={[{required:true, message: "监管地区代码不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormText
                            name={['code']}
                            label="平台编码"
                            tooltip=""
                            placeholder=""
                            rules={[{required:true, message: "平台编码不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormText
                            name={['platformName']}
                            label="平台名称"
                            tooltip=""
                            placeholder=""
                            rules={[{required:true, message: "平台名称不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormSwitch
                            name={['enabled']}
                            label="启用"
                            tooltip=""
                            placeholder=""
                            rules={[{required:true, message: "启用不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormSwitch
                            name={['suspended']}
                            label="暂停报送"
                            tooltip=""
                            placeholder=""
                            rules={[{required:true, message: "暂停报送不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={24}>
                        <ProFormText
                            name={['url']}
                            label="平台URL"
                            tooltip=""
                            placeholder=""
                            rules={[{required:true, message: "平台URL不能为空"}]}
                            {...FormLayout.itemLayout.span24}
                        />
                    </Col>
                    <Col span={24}>
                        <ProFormText
                            name={['apiUrl']}
                            label="接口URL"
                            tooltip=""
                            placeholder=""
                            rules={[{required:true, message: "接口URL不能为空"}]}
                            {...FormLayout.itemLayout.span24}
                        />
                    </Col>
                </Row>
            </ProForm>
        </Drawer>
    );
};

export default IotGovPlatformAddForm;