import _ from 'lodash';

import type { DrawerProps } from 'antd';
import type { ProDescriptionsActionType } from "@ant-design/pro-descriptions";
import type {ProFormProps} from '@ant-design/pro-form';

import React, { useRef } from 'react';
import {Button, Drawer, Space, Row, Col, Form, } from 'antd';
import {FooterToolbar} from '@ant-design/pro-layout';
import ProForm, {ModalForm, DrawerForm, ProFormSwitch, ProFormText, } from '@ant-design/pro-form';

import FormLayout from './../../../../utils/ui/layout/FormLayout';

import { GovPlatformTenantSetting } from './../../type/GovPlatformTenantSettingType';
import GovPlatformTenantSettingApi from '../../api/GovPlatformTenantSettingApi';
import { message } from 'antd';
import GovPlatformSelector from '../GovPlatform/GovPlatformSelector';

export interface GovPlatformTenantSettingAddFormProps extends DrawerProps {
    tenantId: string,
    onFail:()=>void,
    onSuccess:()=>void,
};



const GovPlatformTenantSettingAddForm:React.FC<GovPlatformTenantSettingAddFormProps> = (props)=>{

    const [form] = Form.useForm<GovPlatformTenantSetting>();
    const {onFail, onSuccess, tenantId} = props;

    return (
        <Drawer
            title="新建政府平台租户设置"
            destroyOnClose={false}
            closable={false}
            width={960}
            extra={[
                <Button
                    key='closeBtn'
                    type='primary'
                    style={{backgroundColor:'red', border:0}}
                    onClick={onFail}
                >
                    关闭
                </Button>
            ]}
            {..._.omit(props, 'onFail', 'onSuccess')}
        >
            <ProForm
                submitter={{
                    render: (_, dom) => <div style={{textAlign:'right', marginTop:16}}><Space>{dom}</Space></div>,
                }}
                onFinish={async (values:  GovPlatformTenantSetting)=>{
                    await GovPlatformTenantSettingApi.addGovPlatformTenantSetting({
                        ...values,
                        tenantId: tenantId,
                    });
                    form.resetFields();
                    message.success("新建政府平台租户设置成功！");
                    if(onSuccess){
                        onSuccess();
                    }else{
                        onFail();
                    }
                    return true;
                }}
                form={form}
                layout={'horizontal'}
            >
                <Row>
                    <Col span={24}>
                        <GovPlatformSelector
                            name={['platformId']}
                            label="平台"
                            tooltip=""
                            placeholder=""
                            rules={[{required:false, message: "平台不能为空"}]}
                            {...FormLayout.itemLayout.span24}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormSwitch
                            name={['syncManagerOnlyFlag']}
                            label="只上传关键岗位"
                            tooltip=""
                            placeholder=""
                            rules={[{required:false, message: "只上传关键岗位不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormSwitch
                            name={['callbackAttendanceLogFlag']}
                            label="回调数据记录"
                            tooltip=""
                            placeholder=""
                            rules={[{required:false, message: "回调数据记录"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormSwitch
                            name={['enabled']}
                            label="启用"
                            tooltip=""
                            placeholder=""
                            rules={[{required:false, message: "启用不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}></Col>
                    <Col span={24}>
                        <ProFormText
                            name={['appPlatformCode']}
                            label="应用平台编号"
                            tooltip=""
                            placeholder=""
                            rules={[{required:false, message: "应用平台编号不能为空"}]}
                            {...FormLayout.itemLayout.span24}
                        />
                    </Col>
                    <Col span={24}>
                        <ProFormText
                            name={['appId']}
                            label="APP ID"
                            tooltip=""
                            placeholder=""
                            rules={[{required:false, message: "APP ID不能为空"}]}
                            {...FormLayout.itemLayout.span24}
                        />
                    </Col>
                    <Col span={24}>
                        <ProFormText
                            name={['appKey']}
                            label="APP KEY"
                            tooltip=""
                            placeholder=""
                            rules={[{required:false, message: "APP KEY不能为空"}]}
                            {...FormLayout.itemLayout.span24}
                        />
                    </Col>
                    <Col span={24}>
                        <ProFormText
                            name={['appSecret']}
                            label="APP Secret"
                            tooltip=""
                            placeholder=""
                            rules={[{required:false, message: "APP Secret不能为空"}]}
                            {...FormLayout.itemLayout.span24}
                        />
                    </Col>
                </Row>
            </ProForm>
        </Drawer>
    );
};

export default GovPlatformTenantSettingAddForm;