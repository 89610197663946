import React, {useState, useEffect} from "react";

import type {SelectProps} from 'antd';
import { Select } from "antd";
import ElevatorMachinePlatformApi from "../../api/ElevatorMachinePlatformApi";
import type { ElevatorMachinePlatform } from "../../type/ElevatorMachinePlatformType";
import { ProFormSelectProps } from "@ant-design/pro-form/lib/components/Select";
import { ProFormSelect } from "@ant-design/pro-form";

const ElevatorMachinePlatformSelector:React.FC<ProFormSelectProps> = (props)=>{

    const [options, setOptions] = useState<[]>([]);

    useEffect(()=>{
        ElevatorMachinePlatformApi.findElevatorMachinePlatformList({
            limit: 100,
            deletedFlag: false,
        }).then((result:any)=>{
            const newOptions = result.map((elevatorMachinePlatform:ElevatorMachinePlatform)=>{
                return {
                    key: elevatorMachinePlatform.id,
                    value: elevatorMachinePlatform.id,
                    label: elevatorMachinePlatform.name,
                };
            });

            setOptions(newOptions);
        });
    }, []);

    return (
        <ProFormSelect
            options={options}
            {...props}
        />
    );
};

export default ElevatorMachinePlatformSelector;