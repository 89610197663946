import _ from 'lodash';

import type { DrawerProps } from 'antd';
import type { ProDescriptionsActionType } from "@ant-design/pro-descriptions";

import React, { useRef, useState } from 'react';
import { Button, Drawer, message,   } from 'antd';
import ProDescriptions from '@ant-design/pro-descriptions';
import ProForm, {ProFormText, } from '@ant-design/pro-form';
import { OutsideTowerCraneLog } from './../../type/OutsideTowerCraneLogType';
import OutsideTowerCraneLogApi from '../../api/OutsideTowerCraneLogApi';

export interface OutsideTowerCraneLogDetailProps extends DrawerProps {
    outsideTowerCraneLog?: OutsideTowerCraneLog,
    actionRef?: React.MutableRefObject<ProDescriptionsActionType | undefined>,
    onFail:()=>void,
    onSuccess:()=>void,
};

const OutsideTowerCraneLogDetail:React.FC<OutsideTowerCraneLogDetailProps> = (props)=>{

    const {outsideTowerCraneLog, onFail, onSuccess, actionRef} = props;
    const [title, setTitle] = useState<string|undefined>(outsideTowerCraneLog?.name);

    return (
        <Drawer
            title={title}
            width={960}
            closable={false}
            destroyOnClose={true}
            extra={[
                <Button
                        key='closeBtn'
                        type='primary'
                        style={{backgroundColor:'red', border:0}}
                        onClick={onFail}
                >
                    关闭
                </Button>
            ]}
            {..._.omit(props, 'onFail', 'onSuccess', 'actionRef')}
        >
            <ProDescriptions<OutsideTowerCraneLog>
                column={2}
                actionRef={actionRef}
                request={async ()=>{
                    const result:OutsideTowerCraneLog = await OutsideTowerCraneLogApi.findOutsideTowerCraneLogById(outsideTowerCraneLog?.id||"");
                    setTitle(result?.name);

                    return {
                        success: true,
                        data: result,
                    };
                }}
                editable={{
                    onSave:async (keyPath:any, newData, oldData)=>{
                        const requestData:any = {
                            id: oldData?.id
                        };
                        let rTemp = requestData;
                        let nTemp = newData;

                        for(let i=0; i<keyPath.length; i++){
                            let field = keyPath[i];

                            if(i===keyPath.length-1){
                                rTemp[field] = nTemp[field];
                            }else{
                                rTemp[field] = {};
                                rTemp = rTemp[field];
                                nTemp = nTemp[field];
                            }
                        }

                        await OutsideTowerCraneLogApi.patchEditOutsideTowerCraneLog(requestData);
                        actionRef?.current?.reload();
                        onSuccess();
                    }
                }}
            >
                    <ProDescriptions.Item
                        dataIndex={['outKey']}
                        label="外部记录ID"
                        valueType="text"
                        copyable={false}
                        render={(dom, entity, index, action) => {
                            return (
                                <div>
                                    {dom}
                                </div>
                            );
                        }}
                        renderFormItem={() => {
                            return <ProFormText />;
                        }}
                    />
                    <ProDescriptions.Item
                        dataIndex={['deviceSn']}
                        label="设备序列号"
                        valueType="text"
                        copyable={false}
                        render={(dom, entity, index, action) => {
                            return (
                                <div>
                                    {dom}
                                </div>
                            );
                        }}
                        renderFormItem={() => {
                            return <ProFormText />;
                        }}
                    />
                    <ProDescriptions.Item
                        dataIndex={['deviceTime']}
                        label="设备时间"
                        valueType="text"
                        copyable={false}
                        render={(dom, entity, index, action) => {
                            return (
                                <div>
                                    {dom}
                                </div>
                            );
                        }}
                        renderFormItem={() => {
                            return <ProFormText />;
                        }}
                    />
                    <ProDescriptions.Item
                        dataIndex={['weight']}
                        label="当前吊重(千克)"
                        valueType="text"
                        copyable={false}
                        render={(dom, entity, index, action) => {
                            return (
                                <div>
                                    {dom}
                                </div>
                            );
                        }}
                        renderFormItem={() => {
                            return <ProFormText />;
                        }}
                    />
                    <ProDescriptions.Item
                        dataIndex={['maxWeight']}
                        label="安全吊重(千克)"
                        valueType="text"
                        copyable={false}
                        render={(dom, entity, index, action) => {
                            return (
                                <div>
                                    {dom}
                                </div>
                            );
                        }}
                        renderFormItem={() => {
                            return <ProFormText />;
                        }}
                    />
                    <ProDescriptions.Item
                        dataIndex={['height']}
                        label="当前高度(米)"
                        valueType="text"
                        copyable={false}
                        render={(dom, entity, index, action) => {
                            return (
                                <div>
                                    {dom}
                                </div>
                            );
                        }}
                        renderFormItem={() => {
                            return <ProFormText />;
                        }}
                    />
                    <ProDescriptions.Item
                        dataIndex={['rotary']}
                        label="当前旋转角度(度)"
                        valueType="text"
                        copyable={false}
                        render={(dom, entity, index, action) => {
                            return (
                                <div>
                                    {dom}
                                </div>
                            );
                        }}
                        renderFormItem={() => {
                            return <ProFormText />;
                        }}
                    />
                    <ProDescriptions.Item
                        dataIndex={['radius']}
                        label="当前幅度(米)"
                        valueType="text"
                        copyable={false}
                        render={(dom, entity, index, action) => {
                            return (
                                <div>
                                    {dom}
                                </div>
                            );
                        }}
                        renderFormItem={() => {
                            return <ProFormText />;
                        }}
                    />
                    <ProDescriptions.Item
                        dataIndex={['momentOfForcePercent']}
                        label="力矩百分比"
                        valueType="text"
                        copyable={false}
                        render={(dom, entity, index, action) => {
                            return (
                                <div>
                                    {dom}
                                </div>
                            );
                        }}
                        renderFormItem={() => {
                            return <ProFormText />;
                        }}
                    />
                    <ProDescriptions.Item
                        dataIndex={['ropeMagnification']}
                        label="吊绳倍率"
                        valueType="text"
                        copyable={false}
                        render={(dom, entity, index, action) => {
                            return (
                                <div>
                                    {dom}
                                </div>
                            );
                        }}
                        renderFormItem={() => {
                            return <ProFormText />;
                        }}
                    />
                    <ProDescriptions.Item
                        dataIndex={['slantAngel']}
                        label="倾斜角度"
                        valueType="text"
                        copyable={false}
                        render={(dom, entity, index, action) => {
                            return (
                                <div>
                                    {dom}
                                </div>
                            );
                        }}
                        renderFormItem={() => {
                            return <ProFormText />;
                        }}
                    />
                    <ProDescriptions.Item
                        dataIndex={['slantDirection']}
                        label="倾斜方向"
                        valueType="text"
                        copyable={false}
                        render={(dom, entity, index, action) => {
                            return (
                                <div>
                                    {dom}
                                </div>
                            );
                        }}
                        renderFormItem={() => {
                            return <ProFormText />;
                        }}
                    />
                    <ProDescriptions.Item
                        dataIndex={['windSpeed']}
                        label="当前风速(米/秒)"
                        valueType="text"
                        copyable={false}
                        render={(dom, entity, index, action) => {
                            return (
                                <div>
                                    {dom}
                                </div>
                            );
                        }}
                        renderFormItem={() => {
                            return <ProFormText />;
                        }}
                    />
                    <ProDescriptions.Item
                        dataIndex={['warningStatus']}
                        label="系统预警状态编码"
                        valueType="text"
                        copyable={false}
                        render={(dom, entity, index, action) => {
                            return (
                                <div>
                                    {dom}
                                </div>
                            );
                        }}
                        renderFormItem={() => {
                            return <ProFormText />;
                        }}
                    />
                    <ProDescriptions.Item
                        dataIndex={['alarmStatus']}
                        label="系统报警状态编码"
                        valueType="text"
                        copyable={false}
                        render={(dom, entity, index, action) => {
                            return (
                                <div>
                                    {dom}
                                </div>
                            );
                        }}
                        renderFormItem={() => {
                            return <ProFormText />;
                        }}
                    />
                    <ProDescriptions.Item
                        dataIndex={['irregularitiesStatus']}
                        label="系统违规操作状态编码"
                        valueType="text"
                        copyable={false}
                        render={(dom, entity, index, action) => {
                            return (
                                <div>
                                    {dom}
                                </div>
                            );
                        }}
                        renderFormItem={() => {
                            return <ProFormText />;
                        }}
                    />
                    <ProDescriptions.Item
                        dataIndex={['firstRunDate']}
                        label="首次运行时间"
                        valueType="text"
                        copyable={false}
                        render={(dom, entity, index, action) => {
                            return (
                                <div>
                                    {dom}
                                </div>
                            );
                        }}
                        renderFormItem={() => {
                            return <ProFormText />;
                        }}
                    />
                    <ProDescriptions.Item
                        dataIndex={['lastRunDate']}
                        label="末次运行时间"
                        valueType="text"
                        copyable={false}
                        render={(dom, entity, index, action) => {
                            return (
                                <div>
                                    {dom}
                                </div>
                            );
                        }}
                        renderFormItem={() => {
                            return <ProFormText />;
                        }}
                    />
                    <ProDescriptions.Item
                        dataIndex={['openTime']}
                        label="本次开机时间"
                        valueType="text"
                        copyable={false}
                        render={(dom, entity, index, action) => {
                            return (
                                <div>
                                    {dom}
                                </div>
                            );
                        }}
                        renderFormItem={() => {
                            return <ProFormText />;
                        }}
                    />
                    <ProDescriptions.Item
                        dataIndex={['lastUpdateTime']}
                        label="最近更新时间"
                        valueType="text"
                        copyable={false}
                        render={(dom, entity, index, action) => {
                            return (
                                <div>
                                    {dom}
                                </div>
                            );
                        }}
                        renderFormItem={() => {
                            return <ProFormText />;
                        }}
                    />
                    <ProDescriptions.Item
                        dataIndex={['driverId']}
                        label="驾驶员ID"
                        valueType="text"
                        copyable={false}
                        render={(dom, entity, index, action) => {
                            return (
                                <div>
                                    {dom}
                                </div>
                            );
                        }}
                        renderFormItem={() => {
                            return <ProFormText />;
                        }}
                    />
                    <ProDescriptions.Item
                        dataIndex={['driverName']}
                        label="驾驶员姓名"
                        valueType="text"
                        copyable={false}
                        render={(dom, entity, index, action) => {
                            return (
                                <div>
                                    {dom}
                                </div>
                            );
                        }}
                        renderFormItem={() => {
                            return <ProFormText />;
                        }}
                    />
                    <ProDescriptions.Item
                        dataIndex={['towerCraneLogId']}
                        label="塔吊黑匣子记录ID"
                        valueType="text"
                        copyable={false}
                        render={(dom, entity, index, action) => {
                            return (
                                <div>
                                    {dom}
                                </div>
                            );
                        }}
                        renderFormItem={() => {
                            return <ProFormText />;
                        }}
                    />
            </ProDescriptions>
        </Drawer>
    );
};

export default OutsideTowerCraneLogDetail;