import React, {useState, useRef, useEffect} from "react";

import {Modal, ModalFuncProps, SelectProps} from 'antd';
import { Space, Row, Col, Tag, Button, Table,  message } from "antd";
import CompanyApi from "../../api/CompanyApi";
import type { Company } from "../../type/CompanyType";
import { ProFormSelectProps } from "@ant-design/pro-form/lib/components/Select";
import { ProFormSelect, ProFormText } from "@ant-design/pro-form";
import ProTable, { ProColumns, ActionType} from "@ant-design/pro-table";
import type {Pagination} from './../../../../utils/data/Type';
import TableUtils from './../../../../utils/ui/table/TableUtils';

import {SmallDashOutlined} from '@ant-design/icons';

interface CompanyModalProps extends ModalFuncProps {
    companyList: Company[],
    mode: 'radio'|'checkbox',
}

const CompanyModal:React.FC<CompanyModalProps> = (props)=>{

    const tableActionRef = useRef<ActionType>();

    const [companyList, setCompanyList] = useState<Company[]>(props.companyList||[]);
    const [selectedCompanyIdList, setSelectedCompanyIdList] = useState<string[]>(companyList.map(i=>i.id));
    const [selectedCompanyList, setSelectedCompanyList] = useState<Company[]>([]);


    const columns:ProColumns<Company>[] = [
        {
            key: "rowNo",
            title: "序号",
            dataIndex: "rowNo",
            width: 60,
            hideInSearch: true,
            hideInSetting: true,
            hideInForm: true,
            copyable: false,
        },
        {
            key: "code",
            title: "统一社会信用代码",
            dataIndex: "code",
            width: 100,
            hideInTable: false,
            hideInSearch: false,
            hideInSetting: false,
            hideInForm: false,
            copyable: false,
        },
        {
            key: "name",
            title: "企业名称",
            dataIndex: "name",
            width: 100,
            hideInTable: false,
            hideInSearch: false,
            hideInSetting: false,
            hideInForm: false,
            copyable: false,
        },
        {
            key: "type",
            title: "企业类型",
            dataIndex: "type",
            width: 100,
            hideInTable: false,
            hideInSearch: false,
            hideInSetting: false,
            hideInForm: false,
            copyable: false,
        },
        {
            key: "licenseNo",
            title: "工商注册号",
            dataIndex: "licenseNo",
            width: 100,
            hideInTable: false,
            hideInSearch: false,
            hideInSetting: false,
            hideInForm: false,
            copyable: false,
        },
        {
            key: "areaCode",
            title: "归属地代码",
            dataIndex: "areaCode",
            width: 100,
            hideInTable: false,
            hideInSearch: false,
            hideInSetting: false,
            hideInForm: false,
            copyable: false,
        },
        {
            key: "registeredAddress",
            title: "注册地址",
            dataIndex: "registeredAddress",
            width: 100,
            hideInTable: false,
            hideInSearch: false,
            hideInSetting: false,
            hideInForm: false,
            copyable: false,
        },
        {
            key: "zipCode",
            title: "邮政编码",
            dataIndex: "zipCode",
            width: 100,
            hideInTable: false,
            hideInSearch: false,
            hideInSetting: false,
            hideInForm: false,
            copyable: false,
        },
        {
            key: "registeredDate",
            title: "注册日期",
            dataIndex: "registeredDate",
            width: 100,
            hideInTable: false,
            hideInSearch: false,
            hideInSetting: false,
            hideInForm: false,
            copyable: false,
        },
    ];

    return (
        <Modal
            title="企业基础信息选择器"
            width={920}
            bodyStyle={{backgroundColor:'#ebedf1'}}
            {...props}
            onOk={()=>{
                if(props.onOk){
                    props.onOk(selectedCompanyList);
                }
            }}
        >
            <Row style={{padding:16, backgroundColor:'#ffffff'}}>
                <Col span={3}>
                    已选企业基础信息：
                </Col>
                <Col span={21}>
                    {
                        selectedCompanyList.length===0 ?
                            <div>请先选择企业基础信息</div> :
                            selectedCompanyList.map((company)=>{
                                return (
                                    <Tag
                                        closable={true}
                                        key={company.id}
                                        onClose={()=>{
                                            const newSelectedCompanyIdList = selectedCompanyIdList.filter((item)=>item!==company.id);
                                            const newSelectedCompanyList = selectedCompanyList.filter(item=>item.id!==company.id);

                                            setSelectedCompanyIdList(newSelectedCompanyIdList);
                                            setSelectedCompanyList(newSelectedCompanyList);
                                        }}
                                    >
                                        {company.name}
                                    </Tag>
                                );
                            })
                    }
                </Col>
            </Row>


            <div style={{marginTop:16}}>
                <ProTable<Company, Pagination>
                    headerTitle="系统企业基础信息列表"
                    rowKey="id"
                    columns={columns}
                    search={{
                        filterType: 'light',
                    }}
                    actionRef={tableActionRef}
                    scroll={{x: TableUtils.getTableScrollX(columns)}}
                    rowSelection={{
                        selections: [Table.SELECTION_NONE],
                        defaultSelectedRowKeys: [],
                        type: props.mode,
                        onChange: (selectedRowKeys, selectedRows)=>{
                            const selectedRowKeysStr = selectedRowKeys.map(i=>i.toString());
                            const companyIdList = companyList.map(company=>company.id);

                            const newSelectedCompanyIdList = selectedCompanyIdList.filter(i=>{
                                if(companyIdList.indexOf(i)<0){
                                    return true;
                                }

                                if(selectedRowKeysStr.indexOf(i)>=0){
                                    return true;
                                };

                                return false;
                            });
                            selectedRowKeysStr.forEach(i=>{
                                if(newSelectedCompanyIdList.indexOf(i)<0){
                                    newSelectedCompanyIdList.push(i);
                                }
                            });

                            const newSelectedCompanyList = selectedCompanyList.filter(company=>{
                            return newSelectedCompanyIdList.indexOf(company.id)>=0;
                        });

                        companyList.forEach(company=>{
                            if(newSelectedCompanyIdList.indexOf(company.id)>=0 && selectedCompanyIdList.indexOf(company.id)<0){
                                newSelectedCompanyList.push(company);
                            }
                        });

                        setSelectedCompanyIdList(newSelectedCompanyIdList);
                        setSelectedCompanyList(newSelectedCompanyList);
                    }
                }}
                request={async (params={}, sort, filter)=>{
                    params.page = params.current;
                    const pageInfo = await CompanyApi.findCompanyPage({
                        deletedFlag: false,
                        ...params,
                    });

                    pageInfo.list.forEach((item:any, idx:number)=>{
                        item.rowNo = ((params.current||1)-1)*(params.pageSize||20)+idx+1;
                    });

                    setCompanyList(pageInfo.list);

                    return {
                        success: true,
                        data: pageInfo.list,
                    };
                }}
                />
            </div>

        </Modal>
    );
};

const CompanyModalSelector:React.FC<any> = (props)=>{
    const {form, value} = props;

    const [modalVisible, setModalVisible] = useState<boolean>(false);
    const [options, setOptions] = useState<Company[]>([]);

    useEffect(()=>{
        if(value){
           CompanyApi.findCompanyList({
                id: value,
                limit: value.length,
            }).then((list)=>{
                setOptions(list);
            });
        }
    }, [value]);

    return (
        <>
            <ProFormSelect
                options={options}
                showSearch={true}
                fieldProps={{
                    fieldNames:{
                        label: 'name',
                        value: 'id',
                    },
                    suffixIcon: <SmallDashOutlined
                        onClick={(e)=>{
                            setModalVisible(true);
                        }}
                    />,
                    onClear:()=>{
                        setOptions([]);
                    }
                }}
                {...props}
            />
            <CompanyModal
                visible={modalVisible}
                companyList={options}
                mode={props.mode==='multiple'?'checkbox':'radio'}
                onCancel={()=>{
                    setModalVisible(false);
                }}
                onOk={(companyList:Company[])=>{
                    setOptions(companyList);
                    setModalVisible(false);

                    const values = props.mode==='multiple' ? options.map((item)=>{
                        return item.id;
                    }) : options[0]?.id;
                    setTimeout(()=>{
                        if(form){
                            form.setFieldsValue({[props.name]: values});
                        }
                        if(props.onChange){
                            props.onChange(values);
                        }
                    }, 100);
                }}
            />
        </>
    );
};

export default CompanyModalSelector;