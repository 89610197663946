
import {OutsideDustLog, OutsideDustLogQto} from './../type/OutsideDustLogType';
import HttpInvoker from './../../../utils/http/HttpInvoker';


const addOutsideDustLog = async (params:OutsideDustLog)=>{

    return HttpInvoker.postObject(`/api/tenant/iot/outside-dust-log`, params);
};


const editOutsideDustLog = async (params:OutsideDustLog)=>{

    return HttpInvoker.putObject(`/api/tenant/iot/outside-dust-log/${params.id}`, params);
};


const patchEditOutsideDustLog = async (params:OutsideDustLog)=>{

    return HttpInvoker.patchObject(`/api/tenant/iot/outside-dust-log/${params.id}`, params);
};


const deleteOutsideDustLog = async (params:OutsideDustLog)=>{

    return HttpInvoker.deleteObject(`/api/tenant/iot/outside-dust-log/${params.id}`, params);
};


const findOutsideDustLogById = async (params:string)=>{

    return HttpInvoker.getObject(`/api/tenant/iot/outside-dust-log/${params}`, params);
};

const findOutsideDustLogList = async (params:any)=>{

    return HttpInvoker.getObject(`/api/tenant/iot/outside-dust-log/list`, params);
};

const findOutsideDustLogPage = async (params:any)=>{

    return HttpInvoker.getObject(`/api/tenant/iot/outside-dust-log/page`, params);
};



export default {
    addOutsideDustLog,
    editOutsideDustLog,
    patchEditOutsideDustLog,
    deleteOutsideDustLog,
    findOutsideDustLogById,
    findOutsideDustLogList,
    findOutsideDustLogPage,
};


