import _ from 'lodash';

import type { DrawerProps } from 'antd';
import type { ProDescriptionsActionType } from "@ant-design/pro-descriptions";
import type {ProFormProps} from '@ant-design/pro-form';

import React, { useRef } from 'react';
import {Button, Drawer, Space, Row, Col, Form, } from 'antd';
import {FooterToolbar} from '@ant-design/pro-layout';
import ProForm, {ModalForm, DrawerForm, ProFormText, ProFormDatePicker, } from '@ant-design/pro-form';

import FormLayout from './../../../../utils/ui/layout/FormLayout';

import { Company } from './../../type/CompanyType';
import CompanyApi from '../../api/CompanyApi';
import { message } from 'antd';

export interface CompanyAddFormProps extends DrawerProps {
    onFail:()=>void,
    onSuccess:()=>void,
};



const CompanyAddForm:React.FC<CompanyAddFormProps> = (props)=>{

    const [form] = Form.useForm<Company>();
    const {onFail, onSuccess} = props;

    return (
        <Drawer
            title="新建企业基础信息"
            destroyOnClose={false}
            closable={false}
            width={960}
            extra={[
                <Button
                    key='closeBtn'
                    type='primary'
                    style={{backgroundColor:'red', border:0}}
                    onClick={onFail}
                >
                    关闭
                </Button>
            ]}
            {..._.omit(props, 'onFail', 'onSuccess')}
        >
            <ProForm
                submitter={{
                    render: (_, dom) => <div style={{textAlign:'right', marginTop:16}}><Space>{dom}</Space></div>,
                }}
                onFinish={async (values:  Company)=>{
                    await CompanyApi.addCompany(values);
                    form.resetFields();
                    message.success("新建企业基础信息成功！");
                    if(onSuccess){
                        onSuccess();
                    }else{
                        onFail();
                    }
                    return true;
                }}
                form={form}
                layout={'horizontal'}
            >
                <Row>
                    <Col span={12}>
                        <ProFormText
                            name={['code']}
                            label="统一社会信用代码"
                            tooltip=""
                            placeholder=""
                            rules={[{required:true, message: "统一社会信用代码不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormText
                            name={['name']}
                            label="企业名称"
                            tooltip=""
                            placeholder=""
                            rules={[{required:true, message: "企业名称不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormText
                            name={['type']}
                            label="企业类型"
                            tooltip=""
                            placeholder=""
                            rules={[{required:true, message: "企业类型不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormText
                            name={['licenseNo']}
                            label="工商注册号"
                            tooltip=""
                            placeholder=""
                            rules={[{required:false, message: "工商注册号不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormText
                            name={['areaCode']}
                            label="归属地代码"
                            tooltip=""
                            placeholder=""
                            rules={[{required:true, message: "归属地代码不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={24}>
                        <ProFormText
                            name={['registeredAddress']}
                            label="注册地址"
                            tooltip=""
                            placeholder=""
                            rules={[{required:false, message: "注册地址不能为空"}]}
                            {...FormLayout.itemLayout.span24}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormText
                            name={['zipCode']}
                            label="邮政编码"
                            tooltip=""
                            placeholder=""
                            rules={[{required:false, message: "邮政编码不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormDatePicker
                            name={['registeredDate']}
                            label="注册日期"
                            tooltip=""
                            placeholder=""
                            rules={[{required:true, message: "注册日期不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                </Row>
            </ProForm>
        </Drawer>
    );
};

export default CompanyAddForm;