import AttendanceMachineMetaInfoPage from './pages/AttendanceMachineMetaInfo';
import AttendanceMachinePlatformPage from './pages/AttendanceMachinePlatform';


import DustMachinePlatform from './pages/DustMachinePlatform';
import DustMachineMetaInfo from './pages/DustMachineMetaInfo';
import OutsideDustLog from './pages/OutsideDustLog';

import ElevatorMachinePlatform from './pages/ElevatorMachinePlatform';
import ElevatorMachineMetaInfo from './pages/ElevatorMachineMetaInfo';
import OutsideElevatorLog from './pages/OutsideElevatorLog';

import TowerCraneMachinePlatform from './pages/TowerCraneMachinePlatform';
import TowerCraneMachineMetaInfo from './pages/TowerCraneMachineMetaInfo';
import OutsideTowerCraneLog from './pages/OutsideTowerCraneLog';

import LedMachinePlatform from './pages/LedMachinePlatform';
import LedMachineMetaInfo from './pages/LedMachineMetaInfo';

export default [{
    path: '/iot/attendance-machine-platform',
    title: '考勤机云平台',
    component: AttendanceMachinePlatformPage
}, {
    path: '/iot/attendance-machine-meta-info',
    title: '考勤机元信息',
    component: AttendanceMachineMetaInfoPage
}, {
    path: '/iot/dust-machine-meta-info',
    title: '扬尘设备元信息',
    component: DustMachineMetaInfo
}, {
    path: '/iot/dust-machine-platform',
    title: '扬尘厂家平台',
    component: DustMachinePlatform
}, {
    path: '/iot/outside-dust-log',
    title: '扬尘原始记录',
    component: OutsideDustLog
}, {
    path: '/iot/elevator-machine-platform',
    title: '升降机厂家平台',
    component: ElevatorMachinePlatform
}, {
    path: '/iot/elevator-machine-meta-info',
    title: '升降机元信息',
    component: ElevatorMachineMetaInfo
}, {
    path: '/iot/outside-elevator-log',
    title: '升降机原始记录',
    component: OutsideElevatorLog
}, {
    path: '/iot/tower-crane-machine-platform',
    title: '升降机厂家平台',
    component: TowerCraneMachinePlatform
}, {
    path: '/iot/tower-crane-machine-meta-info',
    title: '升降机元信息',
    component: TowerCraneMachineMetaInfo
}, {
    path: '/iot/outside-tower-crane-log',
    title: '升降机原始记录',
    component: OutsideTowerCraneLog
}, {
    path: '/iot/led-machine-platform',
    title: '升降机厂家平台',
    component: LedMachinePlatform
}, {
    path: '/iot/led-machine-meta-info',
    title: '升降机元信息',
    component: LedMachineMetaInfo
}]