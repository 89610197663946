import React, {useState, useEffect} from "react";

import type {SelectProps} from 'antd';
import { Select } from "antd";
import RoleApi from "../../api/RoleApi";
import type { Role } from "../../type/RoleType";
import { ProFormSelectProps } from "@ant-design/pro-form/lib/components/Select";
import { ProFormSelect } from "@ant-design/pro-form";

const RoleSelector:React.FC<ProFormSelectProps> = (props)=>{

    const [options, setOptions] = useState<[]>([]);

    useEffect(()=>{
        RoleApi.findRoleList({
            limit: 100,
            deletedFlag: false,
        }).then((result:any)=>{
            const newOptions = result.map((role:Role)=>{
                return {
                    key: role.id,
                    value: role.id,
                    label: role.name,
                };
            });

            setOptions(newOptions);
        });
    }, []);

    return (
        <ProFormSelect
            options={options}
            {...props}
        />
    );
};

export default RoleSelector;