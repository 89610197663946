import type { CSSProperties } from 'react';
import { useState } from 'react';
import { useParams, useNavigate } from "react-router-dom";

import { Tabs, Space } from 'antd';
import { LoginForm, ProFormText, ProFormCheckbox } from '@ant-design/pro-form';
import {
  UserOutlined,
  LockOutlined,
} from '@ant-design/icons';

import './LoginPage.less';

import AuthenticationApi from '../../api/AuthenticationApi';


type LoginType = 'account';


export default () => {
  const [loginType, setLoginType] = useState<LoginType>('account');
  const navigate = useNavigate();

  return (
    <div className='loginPage'>
      
      <div className='pageContent'>
        <div className='left'>

        </div>

        <div className='right'>
          <div className='rightRow1'>

          </div>

          <div className='rightRow2'>
            <LoginForm
                title="系统登入"
                subTitle="乐建云工程建设管理后台"
                actions={
                    <Space>

                    </Space>
                }
                onFinish={async (values)=>{
                  const token = await AuthenticationApi.login(values);
                  localStorage.setItem("Authorization", token);

                  navigate("/home");
                }}
            >
                <Tabs activeKey={loginType} onChange={(activeKey) => setLoginType(activeKey as LoginType)}>
                <Tabs.TabPane key={'account'} tab={'账号密码登录'} />
                </Tabs>
                {loginType === 'account' && (
                <>
                    <ProFormText
                    name="username"
                    fieldProps={{
                        size: 'large',
                        prefix: <UserOutlined className={'prefixIcon'} />,
                    }}
                    placeholder={'请输入用户名'}
                    rules={[
                        {
                        required: true,
                        message: '用户名不能为空',
                        },
                    ]}
                    />
                    <ProFormText.Password
                    name="password"
                    fieldProps={{
                        size: 'large',
                        prefix: <LockOutlined className={'prefixIcon'} />,
                    }}
                    placeholder={'请输入密码'}
                    rules={[
                        {
                        required: true,
                        message: '密码不能为空',
                        },
                    ]}
                    />
                </>
                )}
                <div
                style={{
                    marginBottom: 24,
                }}
                >
                <ProFormCheckbox noStyle name="autoLogin">
                    自动登录
                </ProFormCheckbox>
                <a
                    style={{
                    float: 'right',
                    }}
                >
                    忘记密码
                </a>
                </div>
            </LoginForm>
          </div>

          <div  className='rightRow3'>

          </div>
        </div>
      </div>
      
      <div className='pageFooter'>
        版权所有：浙江鸿正软件科技有限公司
      </div>
    </div>
  );
};