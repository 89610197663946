

import mainList from './../modules/main/RouteList';
import systemList from './../modules/system/RouteList';
import iotList from './../modules/iot/RouteList';
import jzList from './../modules/jz/RouteList';
import hrSyncList from './../modules/hr-sync/RouteList';
import iotSyncList from './../modules/iot-sync/RouteList';

export default [
    ...mainList,
    ...systemList,
    ...iotList,
    ...jzList,
    ...hrSyncList,
    ...iotSyncList,
];