
import {DictItem, DictItemQto} from '../type/DictItemType';
import HttpInvoker from '../../../utils/http/HttpInvoker';


const addDictItem = async (params:DictItem)=>{

    return HttpInvoker.postObject(`/api/tenant/system/dict-item`, params);
};


const editDictItem = async (params:DictItem)=>{

    return HttpInvoker.putObject(`/api/tenant/system/dict-item/${params.id}`, params);
};


const patchEditDictItem = async (params:DictItem)=>{

    return HttpInvoker.patchObject(`/api/tenant/system/dict-item/${params.id}`, params);
};


const deleteDictItem = async (params:DictItem)=>{

    return HttpInvoker.deleteObject(`/api/tenant/system/dict-item/${params.id}`, params);
};


const findDictItemById = async (params:string)=>{

    return HttpInvoker.getObject(`/api/tenant/system/dict-item/${params}`, params);
};

const findDictItemList = async (params:any)=>{

    return HttpInvoker.getObject(`/api/tenant/system/dict-item/list`, params);
};

const findDictItemPage = async (params:any)=>{

    return HttpInvoker.getObject(`/api/tenant/system/dict-item/page`, params);
};



export default {
    addDictItem,
    editDictItem,
    patchEditDictItem,
    deleteDictItem,
    findDictItemById,
    findDictItemList,
    findDictItemPage,
};


