
import {IotGovPlatformTenantSetting, IotGovPlatformTenantSettingQto} from './../type/IotGovPlatformTenantSettingType';
import HttpInvoker from './../../../utils/http/HttpInvoker';


const addIotGovPlatformTenantSetting = async (params:IotGovPlatformTenantSetting)=>{

    return HttpInvoker.postObject(`/api/platform/iot-sync/iot-gov-platform-tenant-setting`, params);
};


const editIotGovPlatformTenantSetting = async (params:IotGovPlatformTenantSetting)=>{

    return HttpInvoker.putObject(`/api/platform/iot-sync/iot-gov-platform-tenant-setting/${params.id}`, params);
};


const patchEditIotGovPlatformTenantSetting = async (params:IotGovPlatformTenantSetting)=>{

    return HttpInvoker.patchObject(`/api/platform/iot-sync/iot-gov-platform-tenant-setting/${params.id}`, params);
};


const deleteIotGovPlatformTenantSetting = async (params:IotGovPlatformTenantSetting)=>{

    return HttpInvoker.deleteObject(`/api/platform/iot-sync/iot-gov-platform-tenant-setting/${params.id}`, params);
};


const findIotGovPlatformTenantSettingById = async (params:string)=>{

    return HttpInvoker.getObject(`/api/platform/iot-sync/iot-gov-platform-tenant-setting/${params}`, params);
};

const findIotGovPlatformTenantSettingList = async (params:any)=>{

    return HttpInvoker.getObject(`/api/platform/iot-sync/iot-gov-platform-tenant-setting/list`, params);
};

const findIotGovPlatformTenantSettingPage = async (params:any)=>{

    return HttpInvoker.getObject(`/api/platform/iot-sync/iot-gov-platform-tenant-setting/page`, params);
};



export default {
    addIotGovPlatformTenantSetting,
    editIotGovPlatformTenantSetting,
    patchEditIotGovPlatformTenantSetting,
    deleteIotGovPlatformTenantSetting,
    findIotGovPlatformTenantSettingById,
    findIotGovPlatformTenantSettingList,
    findIotGovPlatformTenantSettingPage,
};


