
import {Company, CompanyQto} from './../type/CompanyType';
import HttpInvoker from './../../../utils/http/HttpInvoker';


const addCompany = async (params:Company)=>{

    return HttpInvoker.postObject(`/api/tenant/jz/company`, params);
};


const editCompany = async (params:Company)=>{

    return HttpInvoker.putObject(`/api/tenant/jz/company/${params.id}`, params);
};


const patchEditCompany = async (params:Company)=>{

    return HttpInvoker.patchObject(`/api/tenant/jz/company/${params.id}`, params);
};


const deleteCompany = async (params:Company)=>{

    return HttpInvoker.deleteObject(`/api/tenant/jz/company/${params.id}`, params);
};


const findCompanyById = async (params:string)=>{

    return HttpInvoker.getObject(`/api/tenant/jz/company/${params}`, params);
};

const findCompanyList = async (params:any)=>{

    return HttpInvoker.getObject(`/api/tenant/jz/company/list`, params);
};

const findCompanyPage = async (params:any)=>{

    return HttpInvoker.getObject(`/api/tenant/jz/company/page`, params);
};



export default {
    addCompany,
    editCompany,
    patchEditCompany,
    deleteCompany,
    findCompanyById,
    findCompanyList,
    findCompanyPage,
};


