
import {ElevatorMachinePlatform, ElevatorMachinePlatformQto} from './../type/ElevatorMachinePlatformType';
import HttpInvoker from './../../../utils/http/HttpInvoker';


const addElevatorMachinePlatform = async (params:ElevatorMachinePlatform)=>{

    return HttpInvoker.postObject(`/api/tenant/iot/elevator-machine-platform`, params);
};


const editElevatorMachinePlatform = async (params:ElevatorMachinePlatform)=>{

    return HttpInvoker.putObject(`/api/tenant/iot/elevator-machine-platform/${params.id}`, params);
};


const patchEditElevatorMachinePlatform = async (params:ElevatorMachinePlatform)=>{

    return HttpInvoker.patchObject(`/api/tenant/iot/elevator-machine-platform/${params.id}`, params);
};


const deleteElevatorMachinePlatform = async (params:ElevatorMachinePlatform)=>{

    return HttpInvoker.deleteObject(`/api/tenant/iot/elevator-machine-platform/${params.id}`, params);
};


const findElevatorMachinePlatformById = async (params:string)=>{

    return HttpInvoker.getObject(`/api/tenant/iot/elevator-machine-platform/${params}`, params);
};

const findElevatorMachinePlatformList = async (params:any)=>{

    return HttpInvoker.getObject(`/api/tenant/iot/elevator-machine-platform/list`, params);
};

const findElevatorMachinePlatformPage = async (params:any)=>{

    return HttpInvoker.getObject(`/api/tenant/iot/elevator-machine-platform/page`, params);
};



export default {
    addElevatorMachinePlatform,
    editElevatorMachinePlatform,
    patchEditElevatorMachinePlatform,
    deleteElevatorMachinePlatform,
    findElevatorMachinePlatformById,
    findElevatorMachinePlatformList,
    findElevatorMachinePlatformPage,
};


