import ProjectInfoPage from './pages/ProjectInfo';
import CompanyPage from './pages/Company';
import PersonPage from './pages/Person';

export default [{
    path: '/jz/project-info',
    title: '项目信息维护',
    component: ProjectInfoPage
}, {
    path: '/jz/company',
    title: '企业信息',
    component: CompanyPage
}, {
    path: '/jz/person',
    title: '个人信息',
    component: PersonPage
}]