
import {GovPlatformTenantSetting, GovPlatformTenantSettingQto} from './../type/GovPlatformTenantSettingType';
import HttpInvoker from './../../../utils/http/HttpInvoker';


const addGovPlatformTenantSetting = async (params:GovPlatformTenantSetting)=>{

    return HttpInvoker.postObject(`/api/platform/hr-sync/gov-platform-tenant-setting`, params);
};


const editGovPlatformTenantSetting = async (params:GovPlatformTenantSetting)=>{

    return HttpInvoker.putObject(`/api/platform/hr-sync/gov-platform-tenant-setting/${params.id}`, params);
};


const patchEditGovPlatformTenantSetting = async (params:GovPlatformTenantSetting)=>{

    return HttpInvoker.patchObject(`/api/platform/hr-sync/gov-platform-tenant-setting/${params.id}`, params);
};


const deleteGovPlatformTenantSetting = async (params:GovPlatformTenantSetting)=>{

    return HttpInvoker.deleteObject(`/api/platform/hr-sync/gov-platform-tenant-setting/${params.id}`, params);
};


const findGovPlatformTenantSettingById = async (params:string)=>{

    return HttpInvoker.getObject(`/api/platform/hr-sync/gov-platform-tenant-setting/${params}`, params);
};

const findGovPlatformTenantSettingList = async (params:any)=>{

    return HttpInvoker.getObject(`/api/platform/hr-sync/gov-platform-tenant-setting/list`, params);
};

const findGovPlatformTenantSettingPage = async (params:any)=>{

    return HttpInvoker.getObject(`/api/platform/hr-sync/gov-platform-tenant-setting/page`, params);
};



export default {
    addGovPlatformTenantSetting,
    editGovPlatformTenantSetting,
    patchEditGovPlatformTenantSetting,
    deleteGovPlatformTenantSetting,
    findGovPlatformTenantSettingById,
    findGovPlatformTenantSettingList,
    findGovPlatformTenantSettingPage,
};


