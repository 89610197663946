import React, {useState, useEffect} from "react";

import type {SelectProps} from 'antd';
import { Select } from "antd";
import IotGovPlatformApi from "../../api/IotGovPlatformApi";
import type { IotGovPlatform } from "../../type/IotGovPlatformType";
import { ProFormSelectProps } from "@ant-design/pro-form/lib/components/Select";
import { ProFormSelect } from "@ant-design/pro-form";

const IotGovPlatformSelector:React.FC<ProFormSelectProps> = (props)=>{

    const [options, setOptions] = useState<[]>([]);

    useEffect(()=>{
        IotGovPlatformApi.findIotGovPlatformList({
            limit: 100,
            deletedFlag: false,
        }).then((result:any)=>{
            const newOptions = result.map((iotGovPlatform:IotGovPlatform)=>{
                return {
                    key: iotGovPlatform.id,
                    value: iotGovPlatform.id,
                    label: iotGovPlatform.platformName,
                };
            });

            setOptions(newOptions);
        });
    }, []);

    return (
        <ProFormSelect
            options={options}
            {...props}
        />
    );
};

export default IotGovPlatformSelector;