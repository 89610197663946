
import {LedMachinePlatform, LedMachinePlatformQto} from './../type/LedMachinePlatformType';
import HttpInvoker from './../../../utils/http/HttpInvoker';


const addLedMachinePlatform = async (params:LedMachinePlatform)=>{

    return HttpInvoker.postObject(`/api/tenant/iot/led-machine-platform`, params);
};


const editLedMachinePlatform = async (params:LedMachinePlatform)=>{

    return HttpInvoker.putObject(`/api/tenant/iot/led-machine-platform/${params.id}`, params);
};


const patchEditLedMachinePlatform = async (params:LedMachinePlatform)=>{

    return HttpInvoker.patchObject(`/api/tenant/iot/led-machine-platform/${params.id}`, params);
};


const deleteLedMachinePlatform = async (params:LedMachinePlatform)=>{

    return HttpInvoker.deleteObject(`/api/tenant/iot/led-machine-platform/${params.id}`, params);
};


const findLedMachinePlatformById = async (params:string)=>{

    return HttpInvoker.getObject(`/api/tenant/iot/led-machine-platform/${params}`, params);
};

const findLedMachinePlatformList = async (params:any)=>{

    return HttpInvoker.getObject(`/api/tenant/iot/led-machine-platform/list`, params);
};

const findLedMachinePlatformPage = async (params:any)=>{

    return HttpInvoker.getObject(`/api/tenant/iot/led-machine-platform/page`, params);
};



export default {
    addLedMachinePlatform,
    editLedMachinePlatform,
    patchEditLedMachinePlatform,
    deleteLedMachinePlatform,
    findLedMachinePlatformById,
    findLedMachinePlatformList,
    findLedMachinePlatformPage,
};


