
import {AttendanceMachinePlatform, AttendanceMachinePlatformQto} from './../type/AttendanceMachinePlatformType';
import HttpInvoker from './../../../utils/http/HttpInvoker';


const addAttendanceMachinePlatform = async (params:AttendanceMachinePlatform)=>{

    return HttpInvoker.postObject(`/api/tenant/iot/attendance-machine-platform`, params);
};


const editAttendanceMachinePlatform = async (params:AttendanceMachinePlatform)=>{

    return HttpInvoker.putObject(`/api/tenant/iot/attendance-machine-platform/${params.id}`, params);
};


const patchEditAttendanceMachinePlatform = async (params:AttendanceMachinePlatform)=>{

    return HttpInvoker.patchObject(`/api/tenant/iot/attendance-machine-platform/${params.id}`, params);
};


const deleteAttendanceMachinePlatform = async (params:AttendanceMachinePlatform)=>{

    return HttpInvoker.deleteObject(`/api/tenant/iot/attendance-machine-platform/${params.id}`, params);
};


const findAttendanceMachinePlatformById = async (params:string)=>{

    return HttpInvoker.getObject(`/api/tenant/iot/attendance-machine-platform/${params}`, params);
};

const findAttendanceMachinePlatformList = async (params:any)=>{

    return HttpInvoker.getObject(`/api/tenant/iot/attendance-machine-platform/list`, params);
};

const findAttendanceMachinePlatformPage = async (params:any)=>{

    return HttpInvoker.getObject(`/api/tenant/iot/attendance-machine-platform/page`, params);
};



export default {
    addAttendanceMachinePlatform,
    editAttendanceMachinePlatform,
    patchEditAttendanceMachinePlatform,
    deleteAttendanceMachinePlatform,
    findAttendanceMachinePlatformById,
    findAttendanceMachinePlatformList,
    findAttendanceMachinePlatformPage,
};


