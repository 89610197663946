
import {OutsideElevatorLog, OutsideElevatorLogQto} from './../type/OutsideElevatorLogType';
import HttpInvoker from './../../../utils/http/HttpInvoker';


const addOutsideElevatorLog = async (params:OutsideElevatorLog)=>{

    return HttpInvoker.postObject(`/api/tenant/iot/outside-elevator-log`, params);
};


const editOutsideElevatorLog = async (params:OutsideElevatorLog)=>{

    return HttpInvoker.putObject(`/api/tenant/iot/outside-elevator-log/${params.id}`, params);
};


const patchEditOutsideElevatorLog = async (params:OutsideElevatorLog)=>{

    return HttpInvoker.patchObject(`/api/tenant/iot/outside-elevator-log/${params.id}`, params);
};


const deleteOutsideElevatorLog = async (params:OutsideElevatorLog)=>{

    return HttpInvoker.deleteObject(`/api/tenant/iot/outside-elevator-log/${params.id}`, params);
};


const findOutsideElevatorLogById = async (params:string)=>{

    return HttpInvoker.getObject(`/api/tenant/iot/outside-elevator-log/${params}`, params);
};

const findOutsideElevatorLogList = async (params:any)=>{

    return HttpInvoker.getObject(`/api/tenant/iot/outside-elevator-log/list`, params);
};

const findOutsideElevatorLogPage = async (params:any)=>{

    return HttpInvoker.getObject(`/api/tenant/iot/outside-elevator-log/page`, params);
};



export default {
    addOutsideElevatorLog,
    editOutsideElevatorLog,
    patchEditOutsideElevatorLog,
    deleteOutsideElevatorLog,
    findOutsideElevatorLogById,
    findOutsideElevatorLogList,
    findOutsideElevatorLogPage,
};


