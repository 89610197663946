
import {IotGovPlatform, IotGovPlatformQto} from './../type/IotGovPlatformType';
import HttpInvoker from './../../../utils/http/HttpInvoker';


const addIotGovPlatform = async (params:IotGovPlatform)=>{

    return HttpInvoker.postObject(`/api/tenant/iot-sync/iot-gov-platform`, params);
};


const editIotGovPlatform = async (params:IotGovPlatform)=>{

    return HttpInvoker.putObject(`/api/tenant/iot-sync/iot-gov-platform/${params.id}`, params);
};


const patchEditIotGovPlatform = async (params:IotGovPlatform)=>{

    return HttpInvoker.patchObject(`/api/tenant/iot-sync/iot-gov-platform/${params.id}`, params);
};


const deleteIotGovPlatform = async (params:IotGovPlatform)=>{

    return HttpInvoker.deleteObject(`/api/tenant/iot-sync/iot-gov-platform/${params.id}`, params);
};


const findIotGovPlatformById = async (params:string)=>{

    return HttpInvoker.getObject(`/api/tenant/iot-sync/iot-gov-platform/${params}`, params);
};

const findIotGovPlatformList = async (params:any)=>{

    return HttpInvoker.getObject(`/api/tenant/iot-sync/iot-gov-platform/list`, params);
};

const findIotGovPlatformPage = async (params:any)=>{

    return HttpInvoker.getObject(`/api/tenant/iot-sync/iot-gov-platform/page`, params);
};



export default {
    addIotGovPlatform,
    editIotGovPlatform,
    patchEditIotGovPlatform,
    deleteIotGovPlatform,
    findIotGovPlatformById,
    findIotGovPlatformList,
    findIotGovPlatformPage,
};


